<template>
  <div class="flex flex-col gap-3 pt-2 text-black dark:text-white">
    <Dropdown
      v-model="operatorValue"
      class="w-full"
      :options="filterOperatorOptions()"
      optionLabel="label"
      optionValue="value"
    />

    <Button
      icon="pi pi-plus"
      label="Add Rule"
      iconPos="left"
      class="p-button-secondary p-button-outlined w-full"
      severity="success"
      @click="addRule()"
    />

    <div
      class="flex flex-col gap-4 overflow-y-auto bg-surface-300 p-3 dark:bg-surface-700"
      style="max-height: 50vh"
      v-if="rules.length > 0"
    >
      <div class="flex flex-col gap-3">
        <div
          v-for="(rule, idx) of rules"
          :key="idx"
          class="flex flex-col gap-2 rounded-lg bg-surface-100 p-2 dark:bg-surface-900"
        >
          <div class="flex w-full gap-3">
            <Dropdown
              v-model="rule.matchMode"
              class="w-full"
              :options="filterMatchOptions(column)"
              optionLabel="label"
              optionValue="value"
            />
            <div v-if="rules.length > 1">
              <Button
                v-tippy="'Delete Rule'"
                icon="pi pi-times"
                severity="warning"
                rounded
                class="h-full w-full"
                @click="removeRule(idx)"
              />
            </div>
          </div>

          <InputNumber
            v-if="column.column_type === ColType.int"
            v-model="rule.value"
            class="w-full"
          />
          <InputNumber
            v-else-if="column.column_type === ColType.float"
            v-model="rule.value"
            :minFractionDigits="0"
            :maxFractionDigits="8"
            class="w-full"
          />
          <Calendar
            v-else-if="column.column_type === ColType.datetime"
            v-model="rule.value"
            showTime
            hourFormat="24"
          />
          <Calendar
            v-else-if="column.column_type === ColType.date"
            v-model="rule.value"
          />
          <div
            v-else-if="column.column_type === ColType.bool"
            class="flex items-center justify-center"
          >
            <div class="p-field-radiobutton mx-2 my-2 flex items-center justify-center">
              <RadioButton
                id="radio1"
                v-model="rule.value"
                class="mx-1"
                name="selectOptions"
                :value="true"
              />
              <label :style="{ cursor: 'pointer' }" for="radio1"> TRUE </label>
            </div>
            <div class="p-field-radiobutton mx-2 flex items-center justify-center">
              <RadioButton
                id="radio2"
                v-model="rule.value"
                class="mx-1"
                name="selectOptions"
                :value="false"
              />
              <label :style="{ cursor: 'pointer' }" for="radio2"> FALSE </label>
            </div>
          </div>
          <div v-else-if="column.choices">
            <Dropdown class="w-full" v-model="rule.value" :options="column.choices" />
          </div>
          <div v-else>
            <InputText v-model="rule.value" class="w-full" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex gap-4">
      <Button
        v-if="rules.length > 0 && rules[0].value"
        type="button"
        icon="pi pi-trash"
        class="min-w-max"
        severity="danger"
        label="Clear All"
        @click="onFilterClear()"
      />
      <Button
        type="button"
        icon="pi pi-check"
        class="w-full"
        severity="info"
        @click="onFilterApply()"
        label="Apply Filter"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, onMounted, ref } from 'vue';

import {
  ColDef,
  ColType,
  GQLState,
  TableClass,
  WhereCondition,
  FieldCondition,
} from '@service/gql';

import {
  FilterOperator,
  FilterMatchMode,
  filterMatchOptions,
  filterOperatorOptions,
  getDefaultOption,
  getDefaultValue,
} from '@components/GQLTable/filter';

// import useCore from '@service/core';
// const c = useCore();

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

const props = defineProps({
  column: {
    type: Object as PropType<ColDef<TableClass>>,
    required: true,
  },
});

const emit = defineEmits(['close']);

interface Rule {
  matchMode: FilterMatchMode;
  value: string | number | boolean | Date | undefined;
}

const operatorValue = ref<FilterOperator>(FilterOperator.AND);

const rules = ref<Rule[]>([]);

function addRule() {
  rules.value.push({
    matchMode: getDefaultOption(props.column),
    value: getDefaultValue(props.column),
  });
}

function removeRule(idx: number) {
  rules.value.splice(idx, 1);
}

function onFilterClear() {
  rules.value = [];
  operatorValue.value = FilterOperator.AND;
}

function ruleToFieldCondition(rule: Rule): FieldCondition {
  let useVal = rule.value;

  // handle craps around '%' wildcard matches
  if (rule.matchMode === FilterMatchMode.CONTAINS) {
    useVal = `%${rule.value}%`;
  } else if (rule.matchMode === FilterMatchMode.STARTS_WITH) {
    useVal = `${rule.value}%`;
  }

  // handle dates
  if (props.column.column_type === ColType.date) {
    const date = useVal as Date;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    useVal = `${year}-${month}-${day}`;
  }
  if (props.column.column_type === ColType.datetime) {
    useVal = (useVal as Date).toISOString();
  }

  return { [rule.matchMode]: useVal };
}

function fieldConditionToRule(fieldCondition: FieldCondition): Rule {
  const matchMode = Object.keys(fieldCondition)[0] as FilterMatchMode;
  let useVal = Object.values(fieldCondition)[0] as
    | string
    | number
    | boolean
    | Date
    | undefined;

  // handle craps around '%' wildcard matches
  if (matchMode === FilterMatchMode.CONTAINS) {
    useVal = (useVal as string).replace(/%/g, '');
  } else if (matchMode === FilterMatchMode.STARTS_WITH) {
    useVal = (useVal as string).replace(/%/g, '');
  }

  // handle dates
  if (
    props.column.column_type === ColType.datetime ||
    props.column.column_type === ColType.date
  ) {
    useVal = new Date(useVal as string);
  }

  return { matchMode: matchMode, value: useVal };
}

/**
query MyQuery {
  auth_user(
    where: {
      _and: [
        { is_active: { _eq: true } }
        {
          _or: [
            { username: { _ilike: "%joel%" } }
            { username: { _ilike: "%jim%" } }
          ]
        }
        { last_login: { _gt: "2024-01-01" } }
        { last_login: { _lt: "2024-03-01" } }
      ]
    }
  ) {
    id
  }
}
*/

onMounted(async () => {
  rules.value = [];
  const localWhere = state.value.localWhere;

  if (localWhere) {
    // Step #1: check if we have any filters in our _and
    const andFilters = localWhere['_and'];
    if (andFilters) {
      // Find filters for the specific column in _and
      const columnFilters = andFilters.filter(
        (filter) => filter[props.column.field] !== undefined,
      );
      if (columnFilters.length > 0) {
        for (const fcon of columnFilters) {
          rules.value.push(fieldConditionToRule(fcon[props.column.field]));
        }
        operatorValue.value = FilterOperator.AND;
      } else {
        // Step #2: check if we have any filters in our _or (within our _and)
        const orFilters = andFilters.filter((filter) => filter['_or'] !== undefined);
        for (const orFilter of orFilters) {
          const orColumnFilters = orFilter['_or'].filter(
            (filter) => filter[props.column.field] !== undefined,
          );
          if (orColumnFilters.length > 0) {
            for (const fcon of orColumnFilters) {
              rules.value.push(fieldConditionToRule(fcon[props.column.field]));
            }
            operatorValue.value = FilterOperator.OR;
            break;
          }
        }
      }
    }
  }

  // Default to AND operator and add a rule if no filters were found
  if (rules.value.length === 0) {
    operatorValue.value = FilterOperator.AND;
    addRule();
  }
});

async function onFilterApply() {
  const newLocalWhere = { ...state.value.localWhere } as WhereCondition<TableClass>;

  console.log(rules.value);

  // Remove existing filters for the given field
  if (newLocalWhere['_and']) {
    newLocalWhere['_and'] = newLocalWhere['_and'].filter((filter) => {
      if (filter[props.column.field] !== undefined) {
        return false;
      }
      if (filter['_or']) {
        filter['_or'] = filter['_or'].filter(
          (orFilter) => orFilter[props.column.field] === undefined,
        );
        return filter['_or'].length > 0;
      }
      return true;
    });
  } else {
    newLocalWhere['_and'] = [];
  }

  // Add new rules based on operatorValue
  if (rules.value.length > 0) {
    if (operatorValue.value === FilterOperator.AND) {
      newLocalWhere['_and'].push(
        ...rules.value
          .filter((rule) => rule.value !== undefined)
          .map((rule) => ({
            [props.column.field]: ruleToFieldCondition(rule),
          })),
      );
    } else if (operatorValue.value === FilterOperator.OR) {
      newLocalWhere['_and'].push({
        _or: rules.value
          .filter((rule) => rule.value !== undefined)
          .map((rule) => ({
            [props.column.field]: ruleToFieldCondition(rule),
          })),
      });
    }
  }

  // Update the state with the new filters
  if (newLocalWhere !== undefined) state.value.localWhere = newLocalWhere;

  console.log('newLocalWhere', newLocalWhere);
  state.value.offset = 0;
  await state.value.refresh();
  emit('close');
}
</script>

<style scoped lang="scss"></style>
