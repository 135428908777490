/* eslint-disable vue/one-component-per-file */
import { defineComponent } from 'vue';
import Dropdown from 'primevue/dropdown';

const CustomDropdown = defineComponent({
  extends: defineComponent({ ...Dropdown }),
  computed: {
    /* we are doing this here because the base visibleOptions in Dropdown applies filtering
       and we don't want that at this point... we want to apply our filter manually from our backend */
    visibleOptions() {
      // @ts-ignore: doing this because this.options is undefined but we know what it is from the base comp
      const opts: Array<Object> = this.options;
      return opts;
    },
  },
});

export default CustomDropdown;
