<template>
  <input
    type="date"
    :class="[
      // FYI: most all of this is copied from lara.inputnumber

      // CUSTOM STUFF
      'w-full',
      'h-[53px]',
      'relative',
      // END CUSTOM STUFF

      // Display
      'flex flex-auto',

      // Font
      'leading-[normal]',

      // Spacing
      'p-3',
      'm-0',

      // Shape
      'rounded-lg',

      // Colors
      'text-surface-800 dark:text-white/80',
      'placeholder:text-surface-400 dark:placeholder:text-surface-500',
      'bg-surface-0 dark:bg-surface-900',
      'border',
      { 'border-surface-300 dark:border-surface-600': !invalid },

      // Invalid State
      'invalid:focus:ring-red-200',
      'invalid:hover:border-red-500',
      { 'border-red-500 dark:border-red-400': invalid },

      // States
      { 'hover:border-primary': !invalid },
      'focus:z-10 focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50',
      { 'pointer-events-none cursor-default select-none opacity-60': disabled },
    ]"
    :value="dateString"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';

import { safeDate } from '@service/helpers';

const props = defineProps({
  modelValue: {
    type: [String, Date],
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const invalid = ref(false);

function onInput(event) {
  const curValue = event.target.value;
  const dval = safeDate(curValue.toString());
  if (dval instanceof Date && !isNaN(dval.getTime())) {
    invalid.value = false;
    emit('update:modelValue', curValue);
    dateString.value = event.target.value;
  } else invalid.value = true;
}

const dateString = ref('');

onMounted(() => setMe());

watch(
  () => props.modelValue,
  (cur, old) => setMe(),
);

function setMe() {
  if (props.modelValue) {
    if (typeof props.modelValue === 'string')
      dateString.value = props.modelValue.split('T')[0];
    else dateString.value = props.modelValue.toISOString().split('T')[0];
  }
}
</script>

<style lang="scss" scoped>
/*
input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  border-width: thin;
}
*/

input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 1rem;
}
input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 1rem;
}
input::-webkit-datetime-edit {
  position: relative;
  right: 1rem;
}
</style>
