import { defineStore } from 'pinia';
import { baseActions } from './actions';
import { State, stateObj } from './state';

const useBaseStore = defineStore({
  id: `${import.meta.env.VITE_ENVNAME}:${import.meta.env.VITE_BUILDDIR}`,
  state: () => stateObj() as State,
  actions: {
    ...baseActions,
    // in each custom store, you can define custom actions here
  },
  persist: true,
});

export function useStore() {
  return useBaseStore();
}
