interface Actions {
  /*
  setSidebarState(newSidebarState: string): void;
  setCounter(newCounter: number): void;
  setLoginUri(newLoginUri: string): void;
  authSuccess(newAuthService: AuthService): void;
  logout(): void;
  setProfile(newProfile: SessionProfile): void;
  setSubscriptionId(newSubscriptionId: string): void;
  setSubscriptionStatus(newSubscriptionStatus: string): void;
  setNavState(newNavState: NavState): void;
  initialTour(newInitialTour: boolean): void;
  joiningDemo(newJoiningDemo: boolean): void;
  buyNow(newBuyNow: boolean): void;
  setTheme(newTheme: string): void;
  isAuthenticated(): boolean;
  loginUri(): string;
  sidebarState(): string;
  companyEuid(): string | null | unknown;
  companyName(): string;
  getLabel(key: string): LabelResult;
  getMetricLayout(): MetricLayoutDefinition;
  getActiveMetric(): CombinedMetric;
  refreshProfile(): void;
  */
}

/*
export interface LabelResult {
  default: string;
  text: string;
}
*/

export const actions: Actions = {
  /*
  authSuccess(this: StoreType, newAuthService: AuthService) {
    this.$patch({
      auth: {
        token: newAuthService.accessToken,
        idToken: newAuthService.idToken,
        profile: newAuthService.profile as Object,
        expiresAt: newAuthService.expiresAt,
      },
    });
    axios.defaults.headers.common.Authorization = `Bearer ${this.auth.token}`;
  },
  logout(this: StoreType) {
    this.$patch({
      auth: {
        token: '',
        idToken: '',
        profile: {},
        expiresAt: 0,
      },
      subscription: {
        id: '',
        status: '',
      },
    });
    delete axios.defaults.headers.common.Authorization;
    EventBusInstance.emit('need-auth-logout');
  },
  isAuthenticated(this: StoreType) {
    const dt = new Date();
    return dt.getTime() < this.auth.expiresAt;
  },
  getCompanyEuid(this: StoreType): string | null | unknown {
    const key1 = 'https://hasura.io/jwt/claims';
    const key2 = 'x-hasura-company';
    if (!(key1 in this.auth.profile)) return null;
    if (!(key2 in (this as any).auth.profile[key1])) return null;
    return (this as any).auth.profile[key1][key2];
  },
  getCompanyName(this: StoreType) {
    return this.profile.entities.name;
  },
  getLabel(this: StoreType, metric: EfficiencyMetric | TransactionMetric): LabelResult {
    // Capitalize first letter and make rest of word lower case
    const defaultValue = metric
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    const res = this.profile.metric_names.overrides[metric];
    return {
      default: defaultValue,
      text: res,
    };
  },
  getMetricLayout(this: StoreType): MetricLayoutDefinition {
    if (this.navstate.layout === 2) return this.profile.metric_layout_2;
    if (this.navstate.layout === 3) return this.profile.metric_layout_3;
    // return layout 1 by default, this layout cannot be deactivated
    return this.profile.metric_layout_1;
  },
  getActiveMetric(this: StoreType): CombinedMetric {
    const metricLayout = this.getMetricLayout();
    if (this.navstate.metric && metricLayout.metrics.includes(this.navstate.metric)) {
      return this.navstate.metric;
    }
    return metricLayout.metrics[0] as CombinedMetric;
  },
  async refreshProfile(this: StoreType) {
    const url = '/v1/company_profile/me/';
    const payload = {
      timestamp: new Date().getTime(),
    };
    const profile = (await API.get(url, payload)) as any;
    let needRefresh = false;
    if (profile['euid'] != this.profile.euid) {
      needRefresh = true;
    }
    this.setProfile(profile);
    if (needRefresh) window.location.reload();
    EventBusInstance.emit('profile-loaded');
  },
  */
};
