<template>
  <div class="guts flex w-full flex-1 items-center justify-center">
    <div
      class="inner m-0 flex flex-1 flex-col items-center justify-center p-2 lg:my-5"
      :class="{
        isLarge: store.$state.isLargeScreen,
        isSmall: !store.$state.isLargeScreen,
      }"
      style="overflow-x: hidden"
    >
      <div class="relative w-full overflow-hidden" style="max-width: 70rem">
        <div
          class="flex h-min transition-transform duration-700"
          :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
        >
          <div
            v-for="(caseItem, index) in cases"
            :key="index"
            class="flex w-full flex-none flex-col items-center justify-center"
          >
            <div class="flex flex-col px-20 text-center">
              <div class="flex flex-col items-center justify-center">
                <div class="mb-1 text-slate-100">
                  {{ caseItem.subtitle }}
                </div>
                <div
                  class="text-100 mb-6 rounded-2xl bg-surface-800 px-0 py-3 text-2xl shadow-md md:px-8"
                >
                  {{ caseItem.text }}
                </div>
              </div>

              <video autoplay muted loop>
                <source :src="'/' + caseItem.image" type="video/webm" />
                Sorry, your browser does not support embedded videos.
              </video>
              <div class="mb-4 font-bold leading-normal text-slate-100">
                {{ caseItem.name }}
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div
          class="absolute left-0 top-0 flex h-full w-full items-center justify-between p-2"
        >
          <Button
            @click="prev"
            icon="pi pi-chevron-left"
            rounded
            :disabled="currentIndex == 0"
          />
          <Button
            @click="next"
            icon="pi pi-chevron-right"
            rounded
            :disabled="currentIndex == cases.length - 1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { useStore } from '@projroot/store';
const store = useStore();

const cases = ref([
  {
    subtitle: 'Use-Case #1',
    text: 'Performance Monitoring',
    image: 'pe_use_1.webm',
    name: 'Track any machine metrics in real-time, all the time.',
  },
  {
    subtitle: 'Use-Case #2',
    text: 'Real-Time Production',
    image: 'pe_use_2.webm',
    name: 'Automatically report production, scrap, and downtime in your ERP.',
  },
  {
    subtitle: 'Use-Case #3',
    text: 'Zero-Cycle-Count Inventory',
    image: 'pe_use_3.webm',
    name: 'Never stop production for full cycle counts again.',
  },
  {
    subtitle: 'Use-Case #4',
    text: 'Trend Analysis & Alerts',
    image: 'pe_use_4.webm',
    name: 'Anticipate and intervene on problems before they stop production.',
  },
]);

onMounted(async () => {});

const currentIndex = ref(0);

const prev = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  }
};

const next = () => {
  if (currentIndex.value < cases.value.length - 1) {
    currentIndex.value++;
  }
};
</script>

<style scoped lang="scss">
.guts {
  background-color: rgb(37, 37, 37);
}

.inner {
  background-color: rgba(0, 0, 0, 0.9);
}

.isLarge {
  border-radius: 1rem;
  max-width: 90vw !important;
}

.isSmall {
  max-width: 100vw;
}

video {
  max-width: 100%;
  // height: auto;
  max-height: 50vh;
  border-radius: 0.2rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
}
</style>
