import RevisionedStore from '../base';

export class InoState extends RevisionedStore {
  paintLine4: boolean = true;
  paintLine5: boolean = true;
  selectedPrograms: string[] = [];
}

export function inoObj() {
  const newState = new InoState();
  return { ...newState };
}
