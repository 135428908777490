<template>
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue';

import { useWindowSize } from '@vueuse/core';

import { useStore } from './store';
const { width } = useWindowSize();

const store = useStore();

watch(width, (cur, old) => {
  handleWidth(cur);
});

function handleWidth(width: number) {
  store.$patch({ isLargeScreen: width > 992 });
}

onMounted(async () => {
  document.documentElement.classList.add('dark');
  document.documentElement.classList.add('theme-orange');
  document.documentElement.classList.add('theme-surface-gray-5');
  handleWidth(window.innerWidth);
});
</script>

<style lang="scss">
html,
body {
  background-color: none;
}
</style>
