import RevisionedStore from './base';

export interface MRPReportGuts {
  company: string;
  group: string;
  partFrom: string;
  partTo: string;
  lineFrom: string;
  lineTo: string;
}

export class MRPReport extends RevisionedStore {
  report: MRPReportGuts = {
    company: '',
    group: '',
    partFrom: '',
    partTo: '',
    lineFrom: '',
    lineTo: '',
  };
}

export function stateObj() {
  const mrpReport = new MRPReport();
  return { ...mrpReport };
}
