import { BaseActions, baseActions } from '@custom/base/store/actions';
import EventBusInstance from '@service/eventBus';
import AuthService from '@service/auth/AuthService';
import { SessionProfile } from '@service/store/profile';
import axios from 'axios';

import { State } from './state';

interface PEActions {
  setLoginUri(newLoginUri: string): void;
  authSuccess(newAuthService: AuthService): void;
  setProfile(newProfile: SessionProfile): void;
  loginUri(): string;
  logout(): void;
  isAuthenticated(): boolean;
  companyEuid(): string | null | unknown;
  companyName(): string;
}
interface Actions extends BaseActions, PEActions {}

type StoreStateActions = State & Actions;

const peActions: PEActions = {
  setLoginUri(this: StoreStateActions, newLoginUri: string) {
    (this as any).loginUri = newLoginUri;
  },
  loginUri(this: StoreStateActions) {
    return this.loginUri;
  },
  isAuthenticated(this: StoreStateActions) {
    const dt = new Date();
    return dt.getTime() < this.auth.expiresAt;
  },
  authSuccess(this: StoreStateActions, newAuthService: AuthService) {
    this.auth.token = newAuthService.accessToken;
    this.auth.idToken = newAuthService.idToken;
    this.auth.profile = newAuthService.profile as Object;
    this.auth.expiresAt = newAuthService.expiresAt;
    axios.defaults.headers.common.Authorization = `Bearer ${this.auth.token}`;
  },
  logout(this: StoreStateActions) {
    this.auth.token = '';
    this.auth.idToken = '';
    this.auth.profile = {};
    this.auth.expiresAt = 0;
    this.profile = {} as SessionProfile;
    this.customer = '';
    this.subscription.id = '';
    this.subscription.status = '';
    delete axios.defaults.headers.common.Authorization;
    EventBusInstance.emit('need-auth-logout');
  },
  setProfile(this: StoreStateActions, newProfile: SessionProfile) {
    this.profile = newProfile;
  },
  companyEuid(this: StoreStateActions): string | null | unknown {
    const key1 = 'https://hasura.io/jwt/claims';
    const key2 = 'x-hasura-company';
    if (!(key1 in this.auth.profile)) return null;
    if (!(key2 in (this as any).auth.profile[key1])) return null;
    return (this as any).auth.profile[key1][key2];
  },
  companyName(this: StoreStateActions) {
    return this.profile.entities.name;
  },
};
export const actions: Actions = {
  ...baseActions,
  ...peActions,
  // define custom actions here
};
