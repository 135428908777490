<template>
  <CustomDropdown
    ref="dropdown"
    v-model="internalValue"
    :options="options"
    optionLabel="name"
    :filter="true"
    :disabled="disabled"
    :showClear="true"
    :loading="loading"
    class="custom-style text-left"
    :autoFilterFocus="true"
    :pt="laraDropdown"
    @before-show="onBeforeShow"
    @change="onDropDownChange"
    @filter="onFilterInput"
  >
    <template v-if="showToggle" #header>
      <div class="-mb-2 ml-4 mt-2 flex h-8 w-full">
        <span class="mr-4 flex"
          ><Toggle
            v-model:selection="internalToggle"
            :toggles="[ToggleVals.LIKE, ToggleVals.EQUALS]"
            :displayText="[ToggleVals.LIKE, ToggleVals.EQUALS]"
            class="priority-input"
            @click.stop
        /></span>
        <slot name="first-slot"></slot>
      </div>
    </template>
    <template #value="slotProps">
      <div v-if="slotProps.value && slotProps.value.special == Special.NONE">
        <template v-if="slotProps.value.selectedLabel">
          <span
            v-for="(data, index) in splitDataOnBreak(slotProps.value.selectedLabel)"
            :key="data + ' ' + index"
          >
            {{ data }}
            <br />
          </span>
        </template>
        <template v-else>
          <span
            v-for="(data, index) in splitDataOnBreak(slotProps.value.label)"
            :key="data + ' ' + index"
          >
            {{ data }}
            <br />
          </span>
        </template>
      </div>
      <span v-else>{{ slotProps.placeholder }}</span>
    </template>
    <template #option="slotProps">
      <div v-if="slotProps.option && slotProps.option.special == Special.MORE">
        <div class="load-more p-2 text-center">Click to Load More Options...</div>
      </div>
      <div v-else>
        <span
          v-for="(data, index) in splitDataOnBreak(slotProps.option.label)"
          :key="data + ' ' + index"
        >
          {{ data }}
          <br />
        </span>
      </div>
    </template>
  </CustomDropdown>
</template>

<script setup lang="ts">
import { ref, PropType, onMounted, watch } from 'vue';

import laraDropdown from './presets/lara';

import { GQLState, OrderBy } from '@service/gql';
import { splitDataOnBreak } from '@service/helpers';

import CustomDropdown from './CustomDropdown';

import Toggle from '@components/Toggle';

import { debounce } from 'lodash';

enum ToggleVals {
  EQUALS = 'EQUALS',
  LIKE = 'LIKE',
}

enum Special {
  NONE,
  MORE,
}
interface Option {
  label: string;
  code: string;
  special: Special;
}
interface DropDownEvent {
  originalEvent: Event;
  value: Option;
}
interface FilterEvent {
  originalEvent: Event;
  value: string;
}

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  showToggle: {
    type: Boolean,
    default: false,
  },
});

const dropdown = ref();
const internalToggle = ref<ToggleVals>(ToggleVals.LIKE);
const loading = ref(true);

const internalValue = ref<Option>();

const options = ref<Array<Option>>([]);
let filterVal = '';

function createFieldFilter(fieldName: string, matchLogic: any) {
  // it's ok to use the straight fieldname here, we take care
  // of breaking '__' name up at query execute time
  // matchLogic: should be something like { _eq: 'value' }
  return {
    _and: [
      {
        [fieldName]: matchLogic,
      },
    ],
  };
}

async function initInternalVal() {
  if (!state.value.valueField) {
    console.error('No valueField defined for GQLDropdown');
    return;
  }

  loading.value = true;
  internalValue.value = undefined;
  state.value.selectedRow = undefined;
  if (state.value.selectedValue) {
    // it's ok to use the straight fieldname here, we take care
    // of breaking '__' name up at query execute time
    state.value.localWhere = createFieldFilter(state.value.valueField, {
      _eq: state.value.selectedValue,
    });

    await state.value.doRows();
    if (state.value.rows !== undefined && state.value.rows.length > 0) {
      const row = state.value.rows[0];
      state.value.selectedRow = row;
      internalValue.value = {
        label: getValueLabel(row),
        code: row[state.value.valueField],
        special: Special.NONE,
      };
    }
  }
  loading.value = false;
}

onMounted(async () => {
  await initInternalVal();
});

watch(
  () => state.value.selectedValue,
  async (newValue, oldValue) => {
    await initInternalVal();
  },
);

watch(
  () => state.value.selectedRow,
  () => {
    if (state.value.onSelect) {
      console.log('calling onSelect', state.value.selectedRow);
      state.value.onSelect(state.value.selectedRow);
    }
  },
);

watch(
  () => internalToggle.value,
  () => {
    getChoices();
  },
);

function getValueLabel(row: Object): string {
  if (state.value.valueLabelCallback) return state.value.valueLabelCallback(row);
  return getChoiceLabel(row);
}

function getChoiceLabel(row: Object): string {
  if (state.value.choiceLabelCallback) return state.value.choiceLabelCallback(row);
  let result: Array<string> = [];
  if (state.value.labelFields) {
    state.value.labelFields.forEach((val) => {
      const tmp = row[val];
      if (tmp) result.push(tmp);
    });
  } else if (state.value.valueField) result.push(row[state.value.valueField]);
  return result.join(': ');
}

async function getChoices() {
  if (!state.value.valueField) {
    console.error('No valueField defined for GQLDropdown');
    return;
  }

  loading.value = true;
  options.value = [];

  // filter fun...
  state.value.localWhere = undefined;
  // if we typed a manual filter, add it to our _and
  if (filterVal) {
    let matchLogic: any = { _ilike: `%${filterVal}%` };
    if (props.showToggle) {
      if (internalToggle.value === ToggleVals.EQUALS) {
        matchLogic = { _eq: filterVal };
      } else matchLogic = { _ilike: `%${filterVal}%` };
    }
    const labelFilters: Array<object> = [];
    if (state.value.labelFields) {
      state.value.labelFields?.forEach((val) => {
        labelFilters.push(createFieldFilter(val, matchLogic));
      });
    } else labelFilters.push(createFieldFilter(state.value.valueField, matchLogic));
    state.value.localWhere = { _or: labelFilters };
  }

  if (state.value.orderBy === undefined) {
    if (state.value.labelFields !== undefined && state.value.labelFields.length > 0)
      state.value.setOrderBy(state.value.labelFields[0], OrderBy.asc);
    else state.value.setOrderBy(state.value.valueField, OrderBy.asc);
  }

  await state.value.doRows();
  await state.value.doCount();

  options.value = [];
  if (state.value.rows !== undefined) {
    for (const row of state.value.rows) {
      options.value.push({
        label: getChoiceLabel(row),
        code: row[state.value.valueField],
        special: Special.NONE,
      });
    }
  }

  // show the 'more records' message if appropriate
  if (state.value.count !== undefined && state.value.count > state.value.limit)
    options.value.push({ label: '', code: '', special: Special.MORE });

  loading.value = false;
}
async function onDropDownChange(e: Event) {
  // type cast me as the actual event we know we'll receive
  const dde = e as unknown as DropDownEvent;
  if (dde.value) {
    if (dde.value.special == Special.MORE) {
      state.value.limit += Number(import.meta.env.VITE_LIST_LIMIT);
      await getChoices();
      // setTimeout(dropdown.value.show, 100);
      // eslint-disable-next-line vue/require-explicit-emits
    } else state.value.selectedValue = dde.value.code;
    // eslint-disable-next-line vue/require-explicit-emits
  } else state.value.selectedValue = undefined;
}

let debounceDelay = 500;
const debouncedGetChoices = debounce(async function () {
  await getChoices();
}, debounceDelay);

async function onFilterInput(e: FilterEvent) {
  if (filterVal != e.value) {
    filterVal = e.value;
    await debouncedGetChoices();
  }
}

async function onBeforeShow() {
  await getChoices();
}
</script>

<style scoped>
.custom-style {
  min-height: 53px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.load-more {
  background-color: black;
  color: orange;
  font-size: 0.8em;
}
</style>
