<template>
  <template v-if="state.rows">
    <tr ref="rowRef" :class="{ highlight: row['id'] == highlightRowId }">
      <template v-for="column in state.columns" :key="column.field">
        <Cell
          v-if="!column.hidden && !(column.column_type === 'hidden')"
          :column="column"
          :value="row[column.field]"
          :rowIdx="idx"
          :row="row"
          :class="{ 'column-scroll': !isRightStatic }"
          :dataKey="dataKey"
          :colorBools="colorBools"
        />
      </template>
      <ButtonCell
        :style="buttonCellStyle"
        :rowIdx="idx"
        :class="{ 'column-fixed': isRightStatic }"
        :buttons="buttons"
        :rows="state.rows"
        :dataKey="dataKey"
      />
    </tr>
  </template>
</template>

<script setup lang="ts">
import { PropType, computed, StyleValue, ref, nextTick, onMounted } from 'vue';

import { useResizeObserver } from '@vueuse/core';

import { ButtonDef } from '../buttons';

import Cell from './Cell.vue';
import ButtonCell from './ButtonCell.vue';
import { GQLState } from '@service/gql';

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

const props = defineProps({
  idx: {
    type: Number,
    required: true,
  },
  row: {
    type: Object as PropType<Object>,
    required: true,
  },
  dataKey: {
    type: String,
    default: 'id',
  },
  buttonColWidth: {
    type: Number,
    required: true,
  },
  buttons: {
    type: Object as PropType<Array<ButtonDef> | null>,
    default: null,
  },
  isRightStatic: {
    type: Boolean,
    default: false,
  },
  isTableScrolling: {
    type: Boolean,
    required: true,
  },
  highlightRowId: {
    type: Number,
    required: false,
    default: 0,
  },
  colorBools: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['height']);

const rowRef = ref<HTMLTableRowElement | undefined>();

useResizeObserver(rowRef, (entries) => {
  const entry = entries[0];
  const { height } = entry.contentRect;
  emit('height', height);
});

onMounted(async () => {
  await nextTick();
});

const buttonCellStyle = computed(() => {
  let baseStyle = {
    width: 'max-content',
    'min-width':
      props.buttons && props.buttons.length > 0
        ? props.buttonColWidth.toString() + 'px'
        : 'auto',
    'padding-right': '6px',
    'padding-left': '6px',
  };
  if (props.isTableScrolling)
    return { position: 'relative', ...baseStyle } as StyleValue;
  else return baseStyle as StyleValue;
});
</script>

<style lang="scss"></style>
