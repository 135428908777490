import gql from 'graphql-tag';
import { ApolloError } from '@apollo/client';
import { Subscription } from 'zen-observable-ts';

import apolloClient from '../apollo_client';
import { tryOnScopeDispose } from '@vueuse/core';

import { FilterOperator, FilterMatchMode } from '@components/GQLTable/filter';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';

export enum OrderBy {
  asc = 'asc',
  desc = 'desc',
}

export enum Align {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum ColType {
  hidden = 'hidden',
  string = 'string',
  bool = 'bool',
  int = 'int',
  float = 'float',
  datetime = 'datetime',
  date = 'date',
  array_count = 'array',
  callback = 'callback',
  json = 'json',
}

type RowCallback<T = any> = (row: T) => string;

export type TableClass = Record<string, any>;

export interface ColDef<T extends TableClass> {
  field: Extract<keyof T, string>;
  column_type?: ColType;
  hidden?: boolean;
  header?: string;
  sortable?: boolean;
  default_order_by?: OrderBy;
  choices?: string[];
  callback?: RowCallback<T>;
  sum?: boolean;
  computed?: boolean;
  decimals?: number; // for column_type == 'float'
  wrap?: string; // for column_type == 'string'
  key?: string; // for json field
  align?: Align;
}

type OrderByCondition<T extends TableClass> = {
  [K in keyof T]?: OrderBy;
};

export type ValueType<T, K extends keyof T> = T[K];

export type FieldCondition = {
  [op in FilterMatchMode]?:
    | string
    | number
    | boolean
    | Date
    | Array<string | number | boolean | Date> // this is for the _in operator
    | undefined;
};

export type Condition<T extends TableClass> = {
  [K in keyof T]?: FieldCondition | AndCondition<T> | OrCondition<T>;
};

interface AndCondition<T extends TableClass> {
  [FilterOperator.AND]: Condition<T>[];
}

interface OrCondition<T extends TableClass> {
  [FilterOperator.OR]: Condition<T>[];
}

export type WhereCondition<T extends TableClass> =
  | AndCondition<T>
  | OrCondition<T>
  | Condition<T>;

type SumValue<T extends TableClass> = {
  [K in keyof T]?: number;
};

/** Matches base GQLess Schema query params */
class _GQLState<T extends TableClass> {
  tableName: string;
  private globalColumns: ColDef<T>[];
  columns: ColDef<T>[];
  distinctOn?: Extract<keyof T, string>;
  orderBy?: OrderByCondition<T>[];
  where?: WhereCondition<T>;
  localWhere?: WhereCondition<T>;
  limit: number;
  realtime: boolean;

  offset: number; // our page x of y control
  error: boolean;
  count?: number;
  rows?: Array<T>;
  sums?: SumValue<T>;

  ///////////////////////////////////////////////////
  // Stuff used by our GQLDropdown
  ///////////////////////////////////////////////////
  // the field to use as the value
  valueField?: Extract<keyof T, string>; // << THIS IS REQUIRED FOR GQLDropdown
  // a list of fields we'll use to craft our label
  labelFields?: Extract<keyof T, string>[];
  // specify a custom generator for each dropdown choice entry if desired
  choiceLabelCallback?: (row: T) => string;
  // the visible text when we select something (only reference fields from labelFields)
  valueLabelCallback?: (row: T) => string;
  // the 'value_field' of the currently selected row
  selectedValue?: ValueType<T, Extract<keyof T, string>> | undefined;
  // the currently selected row
  selectedRow?: T;
  // callback triggered when we select something
  onSelect?: (row: T) => void;

  ///////////////////////////////////////////////////
  // our actual GQL clients (once we call one of the do* methods)
  private rowsClient: ApolloClient<NormalizedCacheObject> | null = null;
  private countClient: ApolloClient<NormalizedCacheObject> | null = null;
  private sumsClient: ApolloClient<NormalizedCacheObject> | null = null;
  private rowsSubscription: Subscription | null = null;
  private countSubscription: Subscription | null = null;
  private sumsSubscription: Subscription | null = null;
  rowsLoading: boolean = false;
  countLoading: boolean = false;
  sumsLoading: boolean = false;

  private constructor(tableName: string, columns: ColDef<T>[]) {
    this.tableName = tableName;
    this.globalColumns = columns;
    // make sure we copy this and don't just grab a ref
    this.columns = JSON.parse(JSON.stringify(this.globalColumns));
    this.prepareColumns(this.columns);
    this.limit = Number(import.meta.env.VITE_LIST_LIMIT);
    this.realtime = false;
    this.offset = 0;
    this.error = false;
  }

  public static createInstance<T extends TableClass>(
    tableName: string,
    columns: ColDef<T>[],
  ): _GQLState<T> {
    // Don't ever call this directly, use the 'useGql' function instead
    return new _GQLState<T>(tableName, columns);
  }

  getOrderBy(fieldName: Extract<keyof T, string>) {
    if (this.orderBy === undefined) return undefined;
    // get the one and only object in the array for this fieldname
    // (cant have multiple order bys on the same field) if it exists
    for (const obj of this.orderBy) {
      if (obj[fieldName] !== undefined) return obj[fieldName];
    }
    return undefined;
  }

  prepareColumns(columns: ColDef<T>[]) {
    // populate any callback columns (or other stuff lost in serialization)
    for (const col of columns) {
      const globalCol = this.globalColumns.find((c) => c.field === col.field);
      if (globalCol?.column_type === ColType.callback) {
        col.callback = globalCol.callback;
      }
    }
  }

  setOrderBy(fieldName: Extract<keyof T, string>, orderBy: OrderBy) {
    if (this.orderBy === undefined) this.orderBy = [];
    // there should only ever be one order by for a field
    // remove any other existing ones before we add our new guy
    this.orderBy = this.orderBy.filter((obj) => obj[fieldName] === undefined);
    // add our new guy
    const useOrderBy = { [fieldName]: orderBy } as OrderByCondition<T>;
    this.orderBy.push(useOrderBy);
  }

  private makeNestedField(fieldName: string, value: any) {
    const splitField = fieldName.split('__');

    let currentLevel = {};
    let nestedObj = currentLevel;

    for (let i = 0; i < splitField.length; i++) {
      const field = splitField[i];

      if (i === splitField.length - 1) {
        nestedObj[field] = value; // set the value at the last field
      } else {
        nestedObj[field] = {}; // create a new level if not at the end
        nestedObj = nestedObj[field]; // move to the next level
      }
    }

    return currentLevel;
  }

  // we want to merge the 'parent' where and any local where within an 'and' condition
  // while also exploding out any field references that are nested
  private getDerivedWhereClause() {
    const query: any[] = [];

    const recurse = (input: any, output: any) => {
      if (Array.isArray(input)) {
        return input.map((item) => recurse(item, {}));
      } else if (typeof input === 'object' && input !== null) {
        Object.entries(input).forEach(([key, value]) => {
          if (key.includes('__')) {
            output = this.makeNestedField(key, recurse(value, {}));
          } else {
            output[key] = recurse(value, {});
          }
        });
        return output;
      } else {
        return input;
      }
    };

    if (this.where !== undefined && Object.keys(this.where).length !== 0) {
      const result = {};
      query.push(recurse(this.where, result));
    }
    if (this.localWhere !== undefined && Object.keys(this.localWhere).length !== 0) {
      const result = {};
      query.push(recurse(this.localWhere, result));
    }
    return { _and: query };
  }

  // build:
  //   [ { auth_user: { username: asc } } ]
  // given:
  //   [ { auth_user__username: 'asc' } ]
  private getDerivedOrderByClause() {
    const result: any[] = [];
    if (this.orderBy === undefined) return result;
    for (const obj of this.orderBy) {
      const fieldName = Object.keys(obj)[0];
      const value = obj[fieldName];
      result.push(this.makeNestedField(fieldName, value));
    }
    return result;
  }

  async close() {
    if (this.rowsSubscription) {
      this.rowsSubscription.unsubscribe();
      this.rowsSubscription = null;
    }
    if (this.rowsClient) {
      await this.rowsClient.stop();
      this.rowsClient = null;
    }
    if (this.countSubscription) {
      this.countSubscription.unsubscribe();
      this.countSubscription = null;
    }
    if (this.countClient) {
      await this.countClient.stop();
      this.countClient = null;
    }
    if (this.sumsSubscription) {
      this.sumsSubscription.unsubscribe();
      this.sumsSubscription = null;
    }
    if (this.sumsClient) {
      await this.sumsClient.stop();
      this.sumsClient = null;
    }
  }

  async refresh() {
    const promises: Promise<void>[] = [];
    if (this.rowsClient) promises.push(this.doRows());
    if (this.countClient) promises.push(this.doCount());
    if (this.sumsClient) promises.push(this.doSums());
    await Promise.all(promises);
  }

  private buildNestedQuery(pathParts) {
    if (pathParts.length === 1) return pathParts[0];
    const [head, ...tail] = pathParts;
    return `${head} { ${this.buildNestedQuery(tail)} }`;
  }

  private flattenObject(obj, colDefs) {
    const result = {};
    colDefs.forEach((colDef) => {
      const fieldParts = colDef.field.split('__');
      let currentValue = obj;
      let shouldAssignNull = false;

      for (let i = 0; i < fieldParts.length; i++) {
        const part = fieldParts[i];

        if (currentValue[part] === null || currentValue[part] === undefined) {
          shouldAssignNull = true;
          break;
        } else if (typeof currentValue[part] === 'object') {
          currentValue = currentValue[part];
        } else if (i === fieldParts.length - 1) {
          result[colDef.field] = currentValue[part];
        }
      }

      if (shouldAssignNull) {
        result[colDef.field] = null;
      }

      // handle json fields
      if (colDef.column_type == ColType.json) {
        result[colDef.field] = currentValue;
      }
    });

    return result;
  }

  private getRowQuery(visibleColumns, isBulk = false) {
    return `
    ${this.realtime && !isBulk ? 'subscription' : 'query'} ${this.tableName}Query($where: ${this.tableName}_bool_exp, $limit: Int, $offset: Int, $orderBy: [${this.tableName}_order_by!], $distinctOn: [${this.tableName}_select_column!]) {
      ${this.tableName} (where: $where, limit: $limit, offset: $offset, order_by: $orderBy, distinct_on: $distinctOn) {
        ${visibleColumns}
      }
    }
    `;
  }

  async doRows() {
    this.rowsLoading = true;

    if (this.rowsSubscription) {
      this.rowsSubscription.unsubscribe();
      this.rowsSubscription = null;
    }

    if (this.rowsClient) {
      await this.rowsClient.stop();
    }
    // this prevents vue from reactivity tracking the client
    this.rowsClient = Object.seal(apolloClient(`${this.tableName}-rows`));

    if (this.orderBy === undefined || this.orderBy.length == 0) {
      this.columns.forEach((col) => {
        if (col.default_order_by) this.setOrderBy(col.field, col.default_order_by);
      });
    }

    const nestedOrderBy = this.getDerivedOrderByClause();

    const mergedWhere = this.getDerivedWhereClause();

    const queryParts = this.columns
      .map((col) => col.field.split('__'))
      .map((path) => this.buildNestedQuery(path));

    const visibleColumns = queryParts.join(' ');

    const baseQuery = this.getRowQuery(visibleColumns);

    const variables = {
      where: mergedWhere,
      limit: this.limit,
      offset: this.offset,
      orderBy: nestedOrderBy,
      distinctOn: this.distinctOn,
    };

    if (this.realtime) {
      this.rowsSubscription = this.rowsClient
        .subscribe({
          query: gql`
            ${baseQuery}
          `,
          variables,
        })
        .subscribe({
          next: async (response) => {
            const data = response.data[this.tableName];
            if (data) {
              this.rows = data.map((item) => this.flattenObject(item, this.columns));
            }
            this.rowsLoading = false;
          },
          error: (error) => console.error('Subscription error:', error),
        });
    } else {
      try {
        const response = await this.rowsClient.query({
          query: gql`
            ${baseQuery}
          `,
          variables,
          fetchPolicy: 'network-only',
        });

        if (response.data && response.data[this.tableName]) {
          this.rows = response.data[this.tableName].map((item) =>
            this.flattenObject(item, this.columns),
          );
        }
        this.rowsLoading = false;
      } catch (e) {
        if (e instanceof ApolloError) {
          console.error('Apollo Error fetching rows:', e);
          e.graphQLErrors.forEach(({ message, locations, path }) => {
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          });
          if (e.networkError) {
            console.error(`[Network error]: ${e.networkError}`);
          }
        } else console.error('Generic Error fetching rows:', e);
      } finally {
        await this.rowsClient.stop();
      }
    }
  }

  // this uses our current query and sorts and whatnot
  //  and fetches ALL rows (not just the current page)
  //  for usage in exporting to excel
  async getAllRows<T>(ROW_LIMIT: number): Promise<T[]> {
    const dumpClient = apolloClient(`${this.tableName}-dump`);

    const nestedOrderBy = this.getDerivedOrderByClause();

    const mergedWhere = this.getDerivedWhereClause();

    const queryParts = this.columns
      .filter((col) => {
        const isHidden = col.hidden || false;
        const isColumnTypeHidden = col.column_type === ColType.hidden;
        return !(isHidden || isColumnTypeHidden);
      })
      .map((col) => col.field.split('__'))
      .map((path) => this.buildNestedQuery(path));

    const visibleColumns = queryParts.join(' ');

    const visibleColumnObjects = this.columns.filter((col) => {
      const isHidden = col.hidden || false;
      const isColumnTypeHidden = col.column_type === ColType.hidden;
      return !(isHidden || isColumnTypeHidden);
    });

    const baseQuery = this.getRowQuery(visibleColumns, true);

    const variables = {
      where: mergedWhere,
      limit: ROW_LIMIT,
      offset: 0,
      orderBy: nestedOrderBy,
      distinctOn: this.distinctOn,
    };

    try {
      const response = await dumpClient.query({
        query: gql`
          ${baseQuery}
        `,
        variables,
        fetchPolicy: 'network-only',
      });

      if (response.data && response.data[this.tableName]) {
        return response.data[this.tableName].map((item) =>
          this.flattenObject(item, visibleColumnObjects),
        );
      }
      return [];
    } catch (error) {
      console.error('Error fetching entities:', error);
      throw error;
    } finally {
      await dumpClient.stop();
    }
  }

  async doCount() {
    this.countLoading = true;
    this.count = undefined;

    if (this.countSubscription) {
      this.countSubscription.unsubscribe();
      this.countSubscription = null;
    }

    if (this.countClient) {
      await this.countClient.stop();
    }
    // this prevents vue from reactivity tracking the client
    this.countClient = Object.seal(apolloClient(`${this.tableName}-count`));

    const mergedWhere = this.getDerivedWhereClause();

    const countQuery = `
      ${this.realtime ? 'subscription' : 'query'} ${this.tableName}Aggregate($where: ${this.tableName}_bool_exp) {
        ${this.tableName}_aggregate(where: $where) {
          aggregate {
            count
          }
        }
      }
    `;

    const variables = {
      where: mergedWhere,
    };

    try {
      if (this.realtime) {
        this.countSubscription = this.countClient
          .subscribe({
            query: gql`
              ${countQuery}
            `,
            variables,
          })
          .subscribe({
            next: (response) => {
              const data = response.data;
              this.count = data[`${this.tableName}_aggregate`].aggregate.count;
              this.countLoading = false;
            },
            error: (error) => {
              console.error('Subscription error:', error);
            },
          });
      } else {
        const response: any = await this.countClient.query({
          query: gql`
            ${countQuery}
          `,
          variables,
        });

        const countData = response.data[`${this.tableName}_aggregate`].aggregate.count;
        this.count = countData;
        this.countLoading = false;
      }
    } catch (error) {
      console.error('Error fetching count:', error);
      this.error = true;
    }
  }

  async doSums() {
    this.sumsLoading = true;
    this.sums = undefined;

    if (this.sumsSubscription) {
      this.sumsSubscription.unsubscribe();
      this.sumsSubscription = null;
    }

    if (this.sumsClient) {
      await this.sumsClient.stop();
    }
    // this prevents vue from reactivity tracking the client
    this.sumsClient = Object.seal(apolloClient(`${this.tableName}-sums`));

    const mergedWhere = this.getDerivedWhereClause();
    const sumColumns = this.columns.filter((col) => col.sum);

    if (sumColumns.length === 0) {
      // console.warn('No columns marked for summing.');
      this.sumsLoading = false;
      this.sums = {};
      return;
    }

    const sumFields = sumColumns
      .map((col) => `${col.field}: sum { ${col.field} }`)
      .join('\n');

    const sumQuery = `
      ${this.realtime ? 'subscription' : 'query'} ${this.tableName}Sum($where: ${this.tableName}_bool_exp) {
        ${this.tableName}_aggregate(where: $where) {
          aggregate {
            ${sumFields}
          }
        }
      }
    `;

    const variables = {
      where: mergedWhere,
    };

    try {
      if (this.realtime) {
        this.sumsSubscription = this.sumsClient
          .subscribe({
            query: gql`
              ${sumQuery}
            `,
            variables,
          })
          .subscribe({
            next: (response) => {
              const data = response.data;
              const sumData = data[`${this.tableName}_aggregate`].aggregate;
              sumColumns.forEach((col) => {
                if (!this.sums) this.sums = {};
                this.sums[col.field] = sumData[col.field]
                  ? sumData[col.field][col.field]
                  : 0;
              });
              this.sumsLoading = false;
            },
            error: (error) => {
              console.error('Subscription error:', error);
            },
          });
      } else {
        const response: any = await this.sumsClient.query({
          query: gql`
            ${sumQuery}
          `,
          variables,
        });

        const sumData = response.data[`${this.tableName}_aggregate`].aggregate;
        sumColumns.forEach((col) => {
          if (!this.sums) this.sums = {};
          this.sums[col.field] = sumData[col.field] ? sumData[col.field][col.field] : 0;
        });
        this.sumsLoading = false;
      }
    } catch (error) {
      console.error('Error fetching sum:', error);
    }
  }
}

export type GQLState<T extends TableClass> = Omit<
  _GQLState<T>,
  'rowsClient' | 'sumsClient' | 'countClient' | 'createInstance'
>;

export function useGql<T extends TableClass>(tableName: string, columns: ColDef<T>[]) {
  const state = _GQLState.createInstance<T>(tableName, columns);

  tryOnScopeDispose(() => {
    (async () => {
      await state.close();
    })();
  });

  return state as GQLState<T>;
}
