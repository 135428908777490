<template>
  <input
    type="datetime-local"
    :class="[
      // FYI: most all of this is copied from lara.inputnumber

      // CUSTOM STUFF
      'h-[53px]',
      'relative',
      // END CUSTOM STUFF

      // Font
      'leading-[normal]',

      // Spacing
      'm-0',
      'p-3',

      // Shape
      'rounded-md',

      // Colors
      'text-surface-950 dark:text-white/80',
      'placeholder:text-surface-400 dark:placeholder:text-surface-500',
      'bg-surface-0 dark:bg-surface-900',
      'border',
      { 'border-surface-300 dark:border-surface-600': !invalid },

      // Invalid State
      'invalid:focus:ring-red-200',
      'invalid:hover:border-red-500',
      { 'border-red-500 dark:border-red-400': invalid },

      // States
      {
        'hover:border-primary': !disabled && !invalid,
        'focus:z-10 focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-500/50 dark:focus:ring-primary-400/50':
          !disabled,
        'pointer-events-none cursor-default select-none opacity-60': disabled,
      },

      // Misc
      'appearance-none',
      'transition-colors duration-200',
    ]"
    :value="dateString"
    :step="stepSize"
    @input="onInput"
  />
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Date,
    default: undefined,
  },
  hasSeconds: {
    type: Boolean,
    deafult: false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

function onInput(event) {
  const curValue = new Date(event.target.value);
  emit('update:modelValue', curValue);
  dateString.value = event.target.value;
}

const stepSize = computed(() => {
  return props.hasSeconds ? 1 : 60;
});

const dateString = ref('');

onMounted(() => {
  setValue();
});

watch(
  () => props.modelValue,
  (cur, old) => {
    setValue();
  },
);

function setValue() {
  if (props.modelValue) {
    let c = new Date(props.modelValue);
    dateString.value =
      c.getFullYear() +
      '-' +
      ('0' + (c.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + c.getDate()).slice(-2) +
      'T' +
      ('0' + c.getHours()).slice(-2) +
      ':' +
      ('0' + c.getMinutes()).slice(-2) +
      ':' +
      ('0' + c.getSeconds()).slice(-2);
  }
}
</script>
