<template>
  <div
    ref="tableContainerRef"
    class="slsgridtable relative flex h-full max-w-full flex-col overflow-hidden"
  >
    <div class="relative flex max-w-full flex-col overflow-hidden">
      <Transition>
        <div
          v-if="showFilterLoadOverlay"
          class="pointer-events-none absolute left-0 top-0 flex h-full w-full items-start justify-center border-4 border-primary-800 pt-2 dark:border-primary-500"
          style="z-index: 100"
        >
          <div
            class="mb-20 flex h-min rounded-xl bg-primary-800 px-4 py-2 font-bold italic text-white dark:bg-primary-600/80 dark:text-black"
          >
            Using previously entered report filters...
          </div>
        </div>
      </Transition>
      <div
        v-if="state.rows === undefined || state.countLoading || state.rowsLoading"
        class="loading-overlay bg-surface-200 dark:bg-surface-800"
      >
        <i class="pi-spin pi pi-spinner dark:text-white"></i>
      </div>
      <!-- Tables -->
      <div
        v-else
        class="relative flex max-w-full flex-col overflow-hidden"
        style="z-index: 3"
      >
        <div
          ref="fixedRightTable"
          class="table-fixed-right table-fixed"
          :class="{ 'table-fixed-hidden': !isTableScrolling }"
        >
          <Guts
            v-model="state"
            v-model:headerRowRef="headerRowRef1"
            v-model:bodyRowHeight="bodyRowHeight1"
            v-model:sumRowRef="sumRowRef1"
            :dataKey="dataKey"
            :buttonColWidth="90"
            :buttons="buttons"
            :isRightStatic="true"
            :isTableScrolling="isTableScrolling"
            :highlightRowId="highlightRowId"
            :colorBools="colorBools"
            :noFilter="noFilter"
            :lottaRowMode="lottaRowMode"
            :hasSumColumn="hasSumColumn"
            @headerClick="$emit('sort', $event)"
            @sortClick="$emit('sort', $event)"
            @filterClick="$emit('filter', $event)"
            @settingsClick="$emit('settings')"
          />
        </div>
        <div ref="scrollTable" class="table-scroll">
          <Guts
            v-model="state"
            v-model:headerRowRef="headerRowRef2"
            v-model:bodyRowHeight="bodyRowHeight2"
            v-model:sumRowRef="sumRowRef2"
            :dataKey="dataKey"
            :buttonColWidth="90"
            :buttons="buttons"
            :isTableScrolling="isTableScrolling"
            :highlightRowId="highlightRowId"
            :colorBools="colorBools"
            :noFilter="noFilter"
            :lottaRowMode="lottaRowMode"
            :hasSumColumn="hasSumColumn"
            @headerClick="$emit('sort', $event)"
            @sortClick="$emit('sort', $event)"
            @filterClick="$emit('filter', $event)"
            @settingsClick="$emit('settings')"
          />
        </div>
        <div
          v-if="state.rows !== undefined && state.rows.length == 0"
          class="empty-overlay flex pt-8 font-bold italic"
        >
          <div
            class="flex h-full w-full items-center justify-center border bg-white dark:border-surface-900 dark:bg-black"
          >
            No results found.
          </div>
        </div>
      </div>

      <!-- Table Footer -->
      <div
        ref="tableFooterRef"
        class="table-footer flex h-min flex-col items-center justify-between lg:flex-row"
      >
        <!-- Pagination -->
        <div
          class="flex max-h-min w-full justify-center"
          :class="{
            'lg:justify-start':
              state.localWhere !== undefined ||
              (showAPILink && !hideFluff) ||
              (showDownload && !hideFluff) ||
              showCreate,
          }"
        >
          <Paginator
            v-if="showPaginator"
            :totalRecords="state.count"
            :rows="state.limit"
            :first="state.offset"
            :template="paginatorTemplate"
            @page="$emit('page', $event)"
          />
        </div>
        <div class="table-buttons max-h-min">
          <Footer
            v-if="!hideFluff"
            v-model="state"
            buttonAPI="API Link"
            :buttonCreate="buttonCreate"
            :showDownload="showDownload"
            :showAPILink="showAPILink"
            :showCreate="showCreate"
            @onCreate="$emit('onCreate')"
            @onDownload="onDownload"
            @apauloDownload="onApauloDownload"
            @onAPILink="$emit('onAPILink')"
          />
        </div>
      </div>

      <div class="border-fudge top"></div>
      <div class="border-fudge right"></div>
      <div class="border-fudge bottom"></div>
      <div class="border-fudge left"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, onMounted, onUnmounted, nextTick, watch, computed } from 'vue';

import { useResizeObserver } from '@vueuse/core';

import { useWindowSize } from '@vueuse/core';

import Paginator from 'primevue/paginator';

import Guts from './Guts.vue';

import { ButtonDef } from '../buttons';

import { GQLState } from '@service/gql';

import Footer from './Footer.vue';
import { hideFluff } from '@service/helpers';
import { downloadXlsx } from '../xlsx';

import { ApauloExcel } from '../frontendXlsx';

import useCore from '@service/core';
const c = useCore();

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

const tableFooterRef = ref<HTMLDivElement | undefined>();

const props = defineProps({
  dataKey: {
    type: String,
    default: 'id',
  },
  buttons: {
    type: Object as PropType<Array<ButtonDef> | null>,
    default: null,
  },
  highlightRowId: {
    type: Number,
    required: false,
    default: 0,
  },
  showDownload: {
    type: Boolean,
    default: false,
  },
  showAPILink: {
    type: Boolean,
    default: false,
  },
  showCreate: {
    type: Boolean,
    default: true,
  },
  buttonCreate: {
    type: String,
    required: true,
  },
  showPaginator: {
    type: Boolean,
    default: true,
  },
  colorBools: {
    type: Boolean,
    required: true,
  },
  noFilter: {
    type: Boolean,
    default: false,
  },
  lottaRowMode: {
    type: Boolean,
    required: true,
  },
  useFrontendExcel: {
    type: Boolean,
    default: false,
  },
  paginatorTemplate: {
    type: [String, Function],
    default: undefined,
  },
});

const { width } = useWindowSize();

const paginatorTemplate = computed(() => {
  if (props.paginatorTemplate) {
    if (typeof props.paginatorTemplate === 'function') {
      return props.paginatorTemplate(width.value);
    }
    return props.paginatorTemplate;
  }
  if (width.value < 640) {
    // tailwind 'sm'
    return 'PrevPageLink CurrentPageReport NextPageLink';
  } else if (width.value < 1024) {
    // tailwind 'lg'
    return 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink';
  } else {
    return 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink';
  }
});

const emit = defineEmits([
  'sort',
  'page',
  'onCreate',
  'onAPILink',
  'filter',
  'settings',
]);

const tableContainerRef = ref();
const fixedRightTable = ref();
const scrollTable = ref();

const headerRowRef1 = ref<HTMLTableRowElement | undefined>();
const headerRowRef2 = ref<HTMLTableRowElement | undefined>();
const bodyRowHeight1 = ref<number>(0);
const bodyRowHeight2 = ref<number>(0);
const sumRowRef1 = ref<HTMLTableRowElement | undefined>();
const sumRowRef2 = ref<HTMLTableRowElement | undefined>();

const isTableScrolling = ref<boolean>(false);

const overlappingStaticTables = ref(false);

//@ts-ignore
const buildDate = ref(__BUILD_DATE__);

useResizeObserver(
  [tableContainerRef, tableFooterRef, headerRowRef2, sumRowRef2],
  calcRowSize,
);

watch([bodyRowHeight2, isTableScrolling, width], calcRowSize);

let reloadTimeout = 0;

function calcRowSize() {
  internalSetScrollTableWidth();

  if (!props.lottaRowMode) return;

  if (bodyRowHeight2.value === 0) {
    return;
  }

  let windowVertSpace = 0;
  if (tableContainerRef.value) {
    const rect = tableContainerRef.value.getBoundingClientRect();
    windowVertSpace = window.innerHeight - rect.top;
  }

  let footerHeight = 0;
  if (tableFooterRef.value) {
    footerHeight = tableFooterRef.value.clientHeight;
  }

  let headerHeight = 0;
  if (headerRowRef2.value) {
    headerHeight = headerRowRef2.value.clientHeight;
  }

  let sumRowHeight = 0;
  if (sumRowRef2.value) {
    sumRowHeight = sumRowRef2.value.clientHeight;
  }

  if (hasSumColumn.value && !sumRowHeight) return;

  if (!headerHeight) return;

  let rowSpace = windowVertSpace - footerHeight - headerHeight - sumRowHeight;

  // decriment the space taken by our scrollbar if we are scrolling
  if (isTableScrolling.value) rowSpace -= 20;

  /*
  console.log(
    'rowSpace',
    rowSpace,
    '(footer)',
    footerHeight,
    '(header)',
    headerHeight,
    '(sum)',
    sumRowHeight,
    '(vertspace)',
    windowVertSpace,
  );
  */

  const limitBefore = state.value.limit;
  state.value.limit = Math.floor(rowSpace / bodyRowHeight2.value);

  if (limitBefore != state.value.limit) {
    // console.log('limit changed', limitBefore, state.value.limit);
    if (reloadTimeout) {
      window.clearTimeout(reloadTimeout);
      reloadTimeout = 0;
    }
    reloadTimeout = window.setTimeout(() => {
      state.value.doRows();
    }, 100);
  }
}

function unsetStaticWidthOverrides() {
  if (!scrollTable.value || !tableContainerRef.value || !fixedRightTable.value) return;

  fixedRightTable.value.style.width = 'unset';
}

function internalSetScrollTableWidth() {
  if (!scrollTable.value || !tableContainerRef.value || !fixedRightTable.value) return;

  // Get width of all tables and the container for the tables
  let tableContainerWidth = tableContainerRef.value.getBoundingClientRect().width;
  let scrollTableWidth = scrollTable.value.getBoundingClientRect().width;
  let fixedRightTableWidth = fixedRightTable.value.getBoundingClientRect().width;

  overlappingStaticTables.value = false;

  // Find the minimum width table
  const minWidth = Math.min(fixedRightTableWidth, scrollTableWidth);

  if (scrollTableWidth > window.innerWidth || tableContainerWidth < minWidth) {
    // Expanding the screen AND scroll is on the table or table is bigger than viewport (including padding/margins)
    unsetStaticWidthOverrides();
    scrollTable.value.style.width = 'unset';
  } else if (tableContainerWidth > minWidth) {
    // Shrinking the screen OR scroll is on the table
    scrollTable.value.style.width = 'max-content';
  }

  fixedRightTable.value.style.overflowX = 'scroll';
  isTableScrolling.value = true;
  if (scrollTableWidth >= fixedRightTableWidth) {
    fixedRightTable.value.style.overflowX = 'auto';
    isTableScrolling.value = false;
  }

  /*
  const rightTableRows = [...fixedRightTable.value.querySelectorAll('table tr')];
  if (rightTableRows && rightTableRows.length > 0) {
    const rightTableFirstFixedColumns = rightTableRows.map(
      (row) =>
        [...row.children].filter((cell) =>
          [...cell.classList].includes('column-fixed'),
        )[0],
    );
    rightTableFirstFixedColumns.forEach((cell) => {
      if (cell) {
        Math.floor(fixedRightTableWidth) > Math.ceil(scrollTableWidth)
          ? cell.classList.add('border-left-highlight')
          : cell.classList.remove('border-left-highlight');
      }
    });
  }
    */
}

watch(props, () => {
  nextTick(() => {
    unsetStaticWidthOverrides();
    internalSetScrollTableWidth();
  });
});

watch(
  () => state.value.rows,
  () => {
    nextTick(() => {
      unsetStaticWidthOverrides();
      internalSetScrollTableWidth();
    });
  },
);

onMounted(() => {});

onUnmounted(() => {});

async function onApauloDownload() {
  c.overlay.show();
  await new ApauloExcel(state, c).run();
  c.overlay.hide();
}

async function onDownload() {
  c.overlay.show();
  await downloadXlsx(state, c);
  c.overlay.hide();
}

const hasSumColumn = computed(() => {
  return state.value.columns.some((column) => column.sum);
});

const showFilterLoadOverlay = ref(false);
function doFilterLoadOverlay() {
  showFilterLoadOverlay.value = true;
  setTimeout(() => {
    showFilterLoadOverlay.value = false;
  }, 3000);
}

defineExpose({
  doFilterLoadOverlay,
});
</script>

<style lang="scss">
@import './style.scss';
</style>
