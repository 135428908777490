import axios from 'axios';
import FontAwesomeIcon from '../fontawesome';

// This is used in make_app.ts
export function setup_axios() {
  let protocol = 'http';
  if (import.meta.env.VITE_BACKEND_SSL == 1) protocol = 'https';
  axios.defaults.baseURL = protocol + '://' + import.meta.env.VITE_BACKEND_SERVER;
}

// This is used in make_app.ts
export function setup_fontawesome(app) {
  app.component('FontAwesomeIcon', FontAwesomeIcon);
}
