<template>
  <td>
    <div
      v-if="column.column_type === ColType.date && typeof value === 'string'"
      :data-key="isDataKeyColumn(column)"
      :class="`text-${column.align ? column.align : 'center'}`"
    >
      {{ dateDisplayFormat(value) }}
    </div>
    <div
      v-else-if="column.column_type === ColType.datetime && typeof value === 'string'"
      :data-key="isDataKeyColumn(column)"
      :class="`text-${column.align ? column.align : 'center'}`"
    >
      {{ datetimeDisplayFormat(value) }}
    </div>
    <div
      v-else-if="column.column_type === ColType.float"
      :data-key="isDataKeyColumn(column)"
      :class="`text-${column.align ? column.align : 'right'}`"
    >
      {{ c.helpers.getColDefFormatedFloat(column, value) }}
    </div>
    <div
      v-else-if="column.column_type === ColType.int"
      :data-key="isDataKeyColumn(column)"
      :class="`text-${column.align ? column.align : 'right'}`"
    >
      {{ value }}
    </div>
    <div
      v-else-if="column.column_type === ColType.bool"
      :data-key="isDataKeyColumn(column)"
      class="flex"
      :class="alignAsFlexJustify"
    >
      <div v-if="value === true">
        <svg
          v-if="colorBools"
          width="44"
          height="40"
          version="1.1"
          viewBox="0 0 167.75 125.94"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(-4.4112e-7 -6.1437)">
            <path
              d="m12.373 45.208c30.804 4.4313 35.956 11.877 56.239 33.207 22.77-47.747 31.44-53.238 67.252-72.271l31.882 34.731c-52.183 32.781-55.063 36.14-91.225 91.211-26.353-25.516-46.515-31.131-76.521-45.332z"
              fill="green"
              stroke-width=".3"
            />
          </g>
        </svg>
        <i v-else class="pi pi-check" />
      </div>
      <div v-else-if="value === false">
        <svg
          v-if="colorBools"
          width="40"
          height="40"
          version="1.1"
          viewBox="0 0 151.56 124.86"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(-1.9858 -8.0596)">
            <path
              d="m38.741 8.4271 41.252 43.121 42.489-43.489 31.065 31.388-45.656 31.525 40.275 31.552-31.071 28.514-40.788-40.578-48.43 42.458-25.891-27.74 47.524-31.733-46.685-36.796z"
              fill="red"
              stroke-width=".3"
            />
          </g>
        </svg>
        <i v-else class="pi pi-times" />
      </div>
      <div v-else>
        <i class="pi pi-question" />
      </div>
    </div>

    <div
      v-else-if="column.column_type === ColType.callback"
      :data-key="isDataKeyColumn(column)"
      :innerHTML="calculatedColumn(column, row)"
      :class="`text-${column.align ? column.align : 'left'}`"
    />

    <div
      v-else
      :style="{
        width: column.wrap ? column.wrap : 'auto',
      }"
      :class="`text-${column.align ? column.align : 'left'}`"
    >
      <tippy
        ref="tippyInstance"
        :interactive="true"
        maxWidth="none"
        :appendTo="documentBody"
      >
        <template #content>
          <div
            v-for="(data, index) in splitDataOnBreak(value)"
            :key="data + ' ' + index"
            style="max-width: 70vw; white-space: wrap"
          >
            {{ data }}
            <br />
          </div>
        </template>
        <div
          :data-key="isDataKeyColumn(column)"
          :style="{
            'max-width': column.wrap ? column.wrap : 'auto',
          }"
          class="string-div"
          ref="myStringDiv"
        >
          <span
            v-for="(data, index) in splitDataOnBreak(value)"
            :key="data + ' ' + index"
          >
            {{ data }}
            <br />
          </span>
        </div>
      </tippy>
    </div>
  </td>
</template>

<script setup lang="ts">
import { PropType, ref, watch, computed } from 'vue';

import { useElementHover } from '@vueuse/core';

import { Align, ColDef, ColType, TableClass } from '@service/gql';
import {
  splitDataOnBreak,
  dateDisplayFormat,
  datetimeDisplayFormat,
} from '@service/helpers';
import { calculatedColumn } from '@components/GQLTable/misc';

import useCore from '@service/core';
const c = useCore();

const props = defineProps({
  value: {
    type: [String, Number, Date, Boolean],
    required: false,
    default: null,
  },
  column: {
    type: Object as PropType<ColDef<TableClass>>,
    required: true,
  },
  rowIdx: {
    type: Number,
    required: true,
  },
  row: {
    type: Object,
    required: true,
  },
  dataKey: {
    type: String,
    default: 'id',
  },
  colorBools: {
    type: Boolean,
    required: true,
  },
});

const documentBody = document.querySelector('body');

const myStringDiv = ref();
const tippyInstance = ref();
const isHovered = useElementHover(myStringDiv);

watch(isHovered, () => {
  if (myStringDiv.value.scrollWidth > myStringDiv.value.clientWidth) {
    tippyInstance.value.enable();
  } else {
    tippyInstance.value.disable();
  }
});

function isDataKeyColumn(column: ColDef<TableClass>): boolean {
  return column.field == props.dataKey;
}

const alignAsFlexJustify = computed(() => {
  if (props.column.align !== undefined) {
    if (props.column.align === Align.left) return 'justify-start';
    if (props.column.align === Align.right) return 'justify-end';
  }
  return 'justify-center';
});
</script>

<style scoped lang="scss">
td {
  white-space: nowrap;
  height: 4rem;
}

.string-div {
  overflow: hidden;
  white-space: nowrap; /* Prevents text from wrapping by default */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed content */
}
</style>
