import API from '@service/api';

const errorThrottle: number[] = [];
let errorCount = 0;

function extractFileNameFromStack(stack) {
  const regex = /https?:\/\/[^/]+(\/.*?\.(js|ts|vue)):/;
  const match = regex.exec(stack);
  if (match) {
    return match[1];
  }
  return null;
}

function getSourcemapUrl(stack) {
  const fileName = extractFileNameFromStack(stack);
  if (!fileName) {
    console.log('Failed to extract file name from stack', stack);
    return null;
  }

  return fileName.replace('.js', '.js.map');
}

async function handleError(
  info: string,
  errorMessage: string,
  stack: string,
  line?: number,
  column?: number,
) {
  const payload = {
    path: window.location.host + window.location.pathname,
    info: info,
    message: errorMessage,
    sourcemapUrl: getSourcemapUrl(stack),
    line: line,
    column: column,
  };

  // don't let us post more than X times in N seconds
  const now = new Date().getTime() / 1000;
  if (errorThrottle.length >= 3) {
    if (now - errorThrottle[0] < 30) {
      console.error('SKIP Error Log Post [FREQUENCY]:', payload);
      return;
    }
    errorThrottle.shift();
  }
  errorThrottle.push(now);
  errorCount += 1;

  // don't ever let us post more than 10 errors before a refresh
  if (errorCount > 10) {
    console.error('SKIP Error Log Post [TOTAL]:', payload);
    return;
  }

  console.error(`Error log Post (${errorCount}):`, payload);

  try {
    await API.post('/backend/v1/error/', payload);
  } catch (e) {
    console.log('Remote Error log post failed!', e);
  }
}

export function errorHandler(err, instance, info) {
  console.log('handling error!', err, instance, info);
  if (err instanceof Error && err.stack) {
    const lines = err.stack.split('\n');

    const match = /:(\d+):(\d+)(?:\s*\))?$/.exec(lines[1] || '');

    let line, column;
    if (match) {
      line = parseInt(match[1], 10);
      column = parseInt(match[2], 10);
    }
    handleError(info, `${err.message}`, err.stack, line, column);
  } else {
    handleError(info, `${err}`);
  }
}
