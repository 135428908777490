<template>
  <Dialog
    v-model:visible="internalVisible"
    :modal="true"
    :autoZIndex="true"
    :breakpoints="breakpoints"
    :style="{ width: width, 'max-width': maxWidth }"
    :contentclass="contentClass"
    :closable="closable"
    @after-hide="$emit('hide')"
  >
    <template #header>
      <slot name="header" />
    </template>

    <slot />
  </Dialog>
</template>

<script setup lang="ts">
import { computed, WritableComputedRef } from 'vue';
import Dialog from 'primevue/dialog';

const props = defineProps({
  closable: {
    type: Boolean,
    default: true,
  },
  contentClass: {
    type: String,
    default: '',
  },
  visible: {
    type: Boolean,
    required: true,
  },
  width: {
    type: String,
    default: '100vw',
  },
  maxWidth: {
    type: String,
    default: '800px',
  },
  breakpoints: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits(['update:visible', 'hide']);

const internalVisible: WritableComputedRef<boolean> = computed({
  get() {
    return props.visible;
  },
  set(value: boolean) {
    emit('update:visible', value);
  },
});
</script>
