import { createApp } from 'vue';
import { setup_axios, setup_fontawesome } from '@custom/base/theme/scripts';
import { errorHandler } from '@custom/base/error_handler';

import App from '@projroot/App.vue';
import router from '@projroot/router';

setup_axios();
// @ts-ignore
const app = createApp(App);
app.use(router);
setup_fontawesome(app);

app.config.errorHandler = errorHandler;
window.addEventListener('unhandledrejection', (event) => {
  event.preventDefault();
  errorHandler(event.reason, null, 'REJECT');
});

export default app;
