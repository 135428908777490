import ExcelJS from 'exceljs';
import { Ref } from 'vue';
import { GQLState, ColType, TableClass } from '@service/gql';

const ROW_LIMIT = 100000;
export class ApauloExcel<T extends TableClass> {
  state: Ref<GQLState<T>>;
  core?: any;
  rows: T[];

  constructor(state, core?) {
    this.state = state;
    this.core = core;
    this.rows = [];
  }

  async run() {
    await this.state.value.doCount();
    if (this.state.value.count !== undefined && this.state.value.count > ROW_LIMIT) {
      this.core.toast.add({
        severity: 'warn',
        summary: 'Warning',
        detail: `The number of records exceeds the limit of ${ROW_LIMIT}. Please refine your search.`,
        life: 10000,
      });
      return;
    }

    this.rows = await this.state.value.getAllRows(ROW_LIMIT);
    this.generateExcelFile();
  }

  constructNestedOrderBy(sortField, sortOrder) {
    const fields = sortField.split('__');
    let orderBy = {};

    orderBy = fields.reduceRight((acc, field) => {
      return { [field]: acc };
    }, sortOrder);

    return [orderBy];
  }

  async generateExcelFile() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const visibleColumns = this.state.value.columns.filter(
      (col) => !(col.hidden || col.column_type === ColType.hidden),
    );

    const headers = visibleColumns.map((col) => col.header);
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD3D3D3' },
      };
    });

    const sums: any = visibleColumns.map((col) => (col.sum ? 0 : null));

    this.rows.forEach((row) => {
      const rowData = worksheet.addRow([]);

      visibleColumns.forEach((col, index) => {
        const value = row[col.field];
        const cell = rowData.getCell(index + 1);

        if (col.sum && !isNaN(parseFloat(value))) {
          sums[index] += parseFloat(value);
        }

        if (col.column_type === ColType.datetime) {
          cell.value = new Date(value).toLocaleString();
          cell.numFmt = 'm/d/yy h:mm:ss AM/PM';
          if (!value) cell.value = '';
        } else {
          cell.value = value;
        }
      });
    });

    const sumLabelsRow = visibleColumns.map((col) => (col.sum ? 'Sum' : ''));
    worksheet.addRow(sumLabelsRow);

    const sumsRow = sums.map((sum) => (sum !== null ? sum : ''));
    worksheet.addRow(sumsRow);

    worksheet.columns.forEach((column: any) => {
      const lengths = column.values.map((v) => v.toString().length);
      const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));
      column.width = maxLength + 2;
    });

    worksheet.views = [{ state: 'frozen', ySplit: 1 }];

    await this.download(workbook);
  }

  async download(workbook: ExcelJS.Workbook) {
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    let fileName;
    if (this.state.value.tableName.includes('_'))
      fileName = this.state.value.tableName.split('_')[1];
    else fileName = this.state.value.tableName;
    link.download = `${fileName}.xlsx`;

    link.click();
    window.URL.revokeObjectURL(url);
  }
}
