import API from '@custom/base/api';

export class Inv2 {
  id: number = 0;
  lot: string = '';
  ref: string = '';
  site: string = '';
  loc: string = '';
  part: string = '';
}

export class ProgInvDetail {
  hold_master_id: number = 0;
  lot: string = '';
  ref: string = '';
  site: string = '';
  loc: string = '';
  part: string = '';
  uom: string = '';
  comment_when: string = '';
  comment_where: string = '';
  comment_why: string = '';
  comment_how: string = '';
  comment_who: string = '';
  comment: string = '';
  hold_reason: string = '';
  sub_reason: string = '';
  desc: string = '';
  user: string = '';
  date: string = '';
  qty_oh: number = 0;
  qty_all: number = 0;
  curr_qty: number = 0;
  can_overissue: boolean = false;
  is_available: boolean = false;
  is_nettable: boolean = false;
  receipt_date?: Date = undefined;
  vendor_lot: string = '';
  vendor: string = '';
  ppap: boolean = false;
}

export class InventoryState {
  filterState: any[] = [];
  sortingState = { sortedColumn: null, sortOrder: null };
  selectedPart = '';
  selectedSite = '';
  selectedLocation = '';
  selectedLot = '';
  selectedRef = '';

  constructor(init?: Partial<InventoryState>) {
    Object.assign(this, init);
  }

  copyState(otherState) {
    Object.keys(otherState).forEach((key) => {
      if (Array.isArray(otherState[key])) {
        this[key] = [...otherState[key]]; // Creates a new array copy
      } else {
        this[key] = otherState[key];
      }
    });
  }

  generateFilterId() {
    // Using Date.now and slice for unique ID generation
    return Date.now().toString(36) + Math.random().toString(36).slice(2);
  }

  addFilter(column, colType, filterType, filterValue) {
    const newFilterId = this.generateFilterId();
    this.filterState.push({
      id: newFilterId,
      column,
      colType,
      filterType,
      filterValue,
    });
  }

  removeFilter(id) {
    // Find the index of the filter with the given id
    const index = this.filterState.findIndex((filter) => filter.id === id);

    // If the filter is found, remove it from the array
    if (index !== -1) {
      this.filterState.splice(index, 1);
    }
  }

  isDefault() {
    return (
      this.filterState.length === 0 &&
      this.sortingState.sortedColumn === null &&
      this.sortingState.sortOrder === null &&
      (this.selectedPart === '' || this.selectedPart === null) &&
      (this.selectedSite === '' || this.selectedSite === null) &&
      (this.selectedLocation === '' || this.selectedLocation === null) &&
      (this.selectedLot === '' || this.selectedLot === null) &&
      (this.selectedRef === '' || this.selectedRef === null)
    );
  }
}

export class WithDelta extends ProgInvDetail {
  delta_qty: number = 0;
}

export async function getByPartLot(
  part: string,
  lot: string,
): Promise<ProgInvDetail[]> {
  const payload = {
    part: part,
    lot: lot,
    location: '',
  };
  const res = (await API.createItem('invv2/get_inventory', payload)) as ProgInvDetail[];
  return res;
}

export async function getByLot(lot: string): Promise<ProgInvDetail[]> {
  const payload = {
    part: '',
    lot: lot,
    location: '',
  };
  const res = (await API.createItem('invv2/get_inventory', payload)) as ProgInvDetail[];
  return res;
}
