import { InventoryState } from '@custom/base/queries/inventory';

export class State {
  theme: string = 'light';
  primaryColor: string = 'cyan';
  surfaceColor: string = 'gray3';
  deviceid: string = '';
  userid: string = '';
  envname: string = '';
  domain: string = '';
  session: string = '';
  timeout: number = 0;
  kiosk: string = '';
  logout: boolean = false;
  timezone: string = 'US/Eastern';
  inventoryState: InventoryState = new InventoryState();
}

// pinia doesn't allow "state: () => new State()"
// so have to do this... lame
export function stateObj() {
  const newState = new State();
  return {
    theme: newState.theme,
    primaryColor: newState.primaryColor,
    surfaceColor: newState.surfaceColor,
    deviceid: newState.deviceid,
    userid: newState.userid,
    envname: newState.envname,
    domain: newState.domain,
    session: newState.session,
    timeout: newState.timeout,
    kiosk: newState.kiosk,
    logout: newState.logout,
    timezone: newState.timezone,
    inventoryState: newState.inventoryState,
  };
}
