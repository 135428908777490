<template>
  <div class="mx-0 overflow-hidden" :class="{ 'h-full': lottaRowMode }">
    <div
      v-if="state.error"
      class="flex h-10 items-center justify-center bg-pink-200 p-10 font-bold text-red-800 dark:bg-pink-950 dark:text-red-300"
    >
      Table Error! (Check console log for details)
    </div>
    <template v-else>
      <TableMain
        ref="tableMainRef"
        :key="tableKey"
        v-model="state"
        :dataKey="dataKey"
        :buttons="buttons"
        :highlightRowId="highlightRowId"
        :showDownload="showDownload"
        :showAPILink="showAPILink"
        :showCreate="showCreate"
        :buttonCreate="buttonCreate"
        :showPaginator="showPaginator"
        :colorBools="colorBools"
        :noFilter="noFilter"
        :lottaRowMode="lottaRowMode"
        :useFrontendExcel="useFrontendExcel"
        :paginatorTemplate="paginatorTemplate"
        class="m-0"
        @sort="onSort($event)"
        @page="onPage($event)"
        @onCreate="$emit('onCreate')"
        @onAPILink="$emit('onAPILink')"
        @filter="onFilter($event)"
        @settings="showSettingsOverlay = true"
      />
    </template>

    <Dialog
      v-if="showSettingsOverlay"
      v-model:visible="showSettingsOverlay"
      modal
      header="Report Settings"
      :style="{ width: '25rem' }"
    >
      <Settings
        v-model="state"
        :key="tableKey"
        :lottaRowMode="lottaRowMode"
        @close="showSettingsOverlay = false"
      />
    </Dialog>

    <Dialog
      v-if="targetFilterColumn && showFilterOverlay"
      v-model:visible="showFilterOverlay"
      modal
      :header="`'${targetFilterColumn.header ? targetFilterColumn.header : targetFilterColumn.field}' Filter`"
      :style="{ width: '25rem' }"
    >
      <Filter
        v-model="state"
        :column="targetFilterColumn"
        @close="showFilterOverlay = false"
      />
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, onMounted, onUnmounted, watch } from 'vue';

import { ButtonDef } from './buttons';

import { ColDef, GQLState, OrderBy, TableClass } from '@service/gql';

import TableMain from './Table/Main.vue';
import Settings from './Modals/Settings.vue';
import Filter from './Modals/Filter.vue';
import { getStorage, setStorage } from './misc';

import useCore from '@service/core';
import { PageState } from 'primevue/paginator';
const c = useCore();

const state = defineModel({
  type: Object as PropType<GQLState<TableClass>>,
  required: true,
});

const props = defineProps({
  highlightRowId: {
    type: Number,
    required: false,
    default: 0,
  },
  dataKey: {
    type: String,
    default: 'id',
  },
  showRemoveButton: {
    type: Boolean,
    default: true,
  },
  showEditButton: {
    type: Boolean,
    default: true,
  },
  buttons: {
    type: Object as PropType<Array<ButtonDef> | null>,
    default: null,
  },
  showPaginator: {
    type: Boolean,
    default: true,
  },
  showDownload: {
    type: Boolean,
    default: false,
  },
  showAPILink: {
    type: Boolean,
    default: false,
  },
  showCreate: {
    type: Boolean,
    default: true,
  },
  buttonCreate: {
    type: String,
    default: 'Create',
  },
  colorBools: {
    type: Boolean,
    default: false,
  },
  noFilter: {
    type: Boolean,
    default: false,
  },
  routeOverride: {
    type: String,
    default: '',
  },
  lottaRowMode: {
    type: Boolean,
    default: false,
  },
  useFrontendExcel: {
    type: Boolean,
    default: false,
  },
  noMemory: {
    type: Boolean,
    default: false,
  },
  paginatorTemplate: {
    type: [String, Function],
    default: undefined,
  },
});

defineEmits(['onRemove', 'onEdit', 'onCreate', 'onAPILink']);

let route = ''; // this is initialized in onMounted now
const showSettingsOverlay = ref<boolean>(false);
const showFilterOverlay = ref<boolean>(false);
const tableKey = ref(0);

const tableMainRef = ref<typeof TableMain>();

//@ts-ignore
const buildDate = ref(__BUILD_DATE__);

const targetFilterColumn = ref<ColDef<TableClass>>();

function onFilter(column: ColDef<TableClass>) {
  targetFilterColumn.value = column;
  showFilterOverlay.value = true;
}

onMounted(async () => {
  await c.router.isReady();

  if (props.routeOverride) route = props.routeOverride;
  else route = c.router.currentRoute.value.path;

  if (!props.noMemory) {
    if (getStorage(route, buildDate.value, state)) {
      if (tableMainRef.value) tableMainRef.value.doFilterLoadOverlay();
    }
    setStorage(route, buildDate.value, state);
  }

  await Promise.all([
    state.value.doRows(),
    state.value.doCount(),
    state.value.doSums(),
  ]);
});

onUnmounted(async () => {
  await state.value.close();
});

async function onPage(pageData: PageState) {
  if (state.value.rowsLoading) return;
  if (!state.value.count) return;
  state.value.offset = pageData.first;
  await state.value.doRows();
}

async function onSort(column: ColDef<TableClass>) {
  if (column.sortable == false) return;
  if (state.value.rowsLoading) return;

  const cur = state.value.getOrderBy(column.field);
  // if we had any other active sorts, clear them
  state.value.orderBy = [];

  // if we are already sorting by this column, flip the order
  if (cur !== undefined && cur == OrderBy.desc)
    state.value.setOrderBy(column.field, OrderBy.asc);
  else state.value.setOrderBy(column.field, OrderBy.desc);

  // reset paging on sort
  state.value.offset = 0;
  await state.value.doRows();
}

watch(
  () => [
    state.value.limit,
    state.value.localWhere,
    state.value.orderBy,
    state.value.columns,
  ],
  () => {
    if (!props.noMemory) setStorage(route, buildDate.value, state);
  },
  { deep: true },
);
</script>

<style scoped></style>
