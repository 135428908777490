import { State as BaseState, stateObj as baseStateObj } from '@custom/base/store/state';
import { SessionProfile } from '@service/store/profile';

export class State extends BaseState {
  theme: string = 'light';
  loginUri: string = '';
  isLargeScreen: boolean = false;
  doingNavAnimation: boolean = false;

  auth = {
    profile: {},
    token: '',
    idToken: '',
    expiresAt: 0,
  };
  counter: number = 0;
  profile: SessionProfile = {} as SessionProfile;
  subscription = {
    status: '',
    id: '',
  };
  customer: string = '';
  // navstate: NavState = {} as NavState;
  initial_tour: boolean = false;
  joining_demo: boolean = false;
}

export function stateObj() {
  const newState = new State();
  const baseState = baseStateObj();
  return {
    ...baseState,
    theme: newState.theme,
    loginUri: newState.loginUri,
    isLargeScreen: newState.isLargeScreen,
    doingNavAnimation: newState.doingNavAnimation,

    auth: newState.auth,
    counter: newState.counter,
    profile: newState.profile,
    subscription: newState.subscription,
    customer: newState.customer,
    // navstate: newState.navstate,
    initial_tour: newState.initial_tour,
    joining_demo: newState.joining_demo,
  };
}
