<template>
  <div class="guts flex w-full flex-1 items-center justify-center">
    <div
      class="inner m-0 flex flex-1 flex-col items-center justify-center gap-4 p-0 lg:m-4 lg:items-start lg:justify-end lg:p-12"
      :class="{
        isLarge: store.$state.isLargeScreen,
        isSmall: !store.$state.isLargeScreen,
      }"
    >
      <div class="text-frame picture flex flex-col gap-4 p-6 text-lg lg:rounded">
        <div class="xxtitle xxoutline mb-2">Consulting</div>
        <div>
          Transform your manufacturing operations with our consulting services in Smart
          Factory and Enterprise Optimization. At the heart of the industry 4.0
          revolution, our team specializes in turning traditional factories into smart,
          integrated, and highly efficient ecosystems.
        </div>
        <div>
          Leveraging the powerful Pocket-E Smart Factory Platform, we provide tailored
          solutions that automate processes, integrate systems in real-time, and
          significantly reduce data errors and latency.
        </div>
        <div>
          With a proven track record of solving complex challenges and creating tangible
          opportunities for growth, our consulting services are your gateway to a
          smarter, more profitable manufacturing future. Let us help you navigate the
          complexities of digital transformation and unlock the full potential of your
          enterprise.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

import { useStore } from '@projroot/store';
const store = useStore();

onMounted(async () => {});
</script>

<style scoped lang="scss">
.guts {
  background-color: rgba(0, 0, 0, 0.9);
}

.inner {
  background-image: url('/pe_smartfactory.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 80vh;
}

.isLarge {
  max-width: 80rem;
}

.text-frame {
  background-color: rgba(0, 24, 39, 0.9);
  max-width: 28rem;
}

.xxtitle {
  font-size: 4rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.9);
}
.xxoutline {
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}
</style>
