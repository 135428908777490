<template>
  <div class="flex min-h-min min-w-max">
    <template v-for="(toggle, i) in allToggles" :key="toggle">
      <div
        v-if="!disabledToggles.includes(toggle.name)"
        class="toggle flex flex-1 cursor-pointer items-center justify-center border border-surface-300 p-2 font-bold dark:border-surface-600"
        :class="{
          'rounded-l-lg border-l': i === 0,
          'rounded-r-lg': i === allToggles.length - 1,
          'enabled primary-content': toggle.on,
          'bg-gray-300 transition-all hover:bg-gray-400 hover:shadow-xl dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500':
            !toggle.on,
        }"
        @click="doClick(toggle.name)"
      >
        {{ toggle.text }}
      </div>
      <div
        v-else
        class="flex flex-1 items-center justify-center border border-l-0 border-surface-900 bg-gray-300 p-2 font-bold"
        :class="{ 'border-l': i === 0 }"
      >
        {{ toggle.text }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from 'vue';
import useCore from '@service/core';
const c = useCore();

interface Toggle {
  name: string;
  on: boolean;
  text: string;
}

const props = defineProps({
  selection: {
    type: String,
    required: true,
  },
  disabledToggles: {
    type: Array as PropType<string[]>,
    required: false,
    default: () => [],
  },
  toggles: {
    type: Array as PropType<string[]>,
    required: true,
  },
  displayText: {
    type: Array as PropType<string[]>,
    required: false,
    default: null,
  },
  confirmMode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:selection']);

const internalSelection = computed({
  get: () => props.selection,
  set: (value) => emit('update:selection', value),
});

function doClick(toggleName: string) {
  if (props.confirmMode) {
    c.confirm.require({
      header: 'Confirm Change',
      message: 'Are you sure you want to change the toggle to ' + toggleName + '?',
      accept: () => {
        internalSelection.value = toggleName;
      },
    });
  } else internalSelection.value = toggleName;
}

const allToggles = computed(() => {
  const toggleArray: Toggle[] = [];
  props.toggles.forEach((name, i) => {
    if (name.toLowerCase() === internalSelection.value.toLowerCase()) {
      toggleArray.push({
        name: name,
        on: true,
        text: props.displayText ? props.displayText[i] : name,
      });
    } else {
      toggleArray.push({
        name: name,
        on: false,
        text: props.displayText ? props.displayText[i] : name,
      });
    }
  });
  return toggleArray;
});
</script>

<style scoped lang="scss">
.enabled {
  background-color: rgb(var(--primary));
}
</style>
