import RevisionedStore from './base';

type StateTabs = { [key: string]: string };

interface StateUser {
  email: string;
  login: number;
  session_id: string;
  tabs: StateTabs;
  user_id: string;
  username: string;
}

export interface StateScheduledDowntime {
  pk: number;
  start_epoch: number;
  end_epoch: number;
  time_epoch?: number;
  updated_epoch: number;
  message: string;
}

export class BackendState extends RevisionedStore {
  euid: string = '';
  name: string = '';
  desc: string | null = '';
  last_input: number = 0;
  sch_queue: number = 0;
  ent_queue: number = 0;
  sch_joblog: number = 0;
  ent_joblog: number = 0;
  users: StateUser[] = [];
  progress_inpevents: number = 0;
  progress_inpschedules: number = 0;
  progress_parts: number = 0;
  progress_reasons: number = 0;
  progress_shifts: number = 0;
  errors: any[] = [];
  scheduled_downtimes: StateScheduledDowntime[] = [];
}

export function stateObj() {
  const state = new BackendState();
  return {
    ...state,
  };
}

type SuperDict = { [key: string]: BackendState };
export class SuperState extends RevisionedStore {
  SUPER: SuperDict = {};
}

export function superStateObj() {
  const state = new SuperState();
  return {
    ...state,
  };
}
