export enum NavState {
  Welcome = 'Welcome',
  SmartFactory = 'SmartFactory',
  UseCases = 'UseCases',
  Efiato = 'Efiato',
  Consulting = 'Consulting',
}

export enum SpecialState {
  Legal = 'Legal',
  Privacy = 'Privacy',
}
