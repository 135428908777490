<template>
  <div
    class="flex flex-1 flex-col gap-12 p-8 lg:gap-4"
    :class="{
      isLarge: store.$state.isLargeScreen,
      isSmall: !store.$state.isLargeScreen,
    }"
  >
    <div class="flex flex-1 items-center justify-center text-5xl font-bold">
      Why Smart Factory?
    </div>
    <div class="flex flex-1 flex-col items-center justify-center gap-12 lg:flex-row">
      <div class="text-block xxoutline w-full">
        <p>Traditional factories see two levels of communication:</p>

        <ul class="ml-8 list-disc">
          <li>Top Floor users interact with the ERP</li>
          <li>Shop Floor users interact with machines</li>
        </ul>

        <p>
          Shop Floor users have no visibility when production requirements or other Top
          Floor data changes.
        </p>
        <p>
          Top Floor users have no visibility when machines break or other Shop Floor
          conditions change.
        </p>
      </div>

      <div class="flex flex-1 flex-col items-center justify-center gap-2 text-center">
        <div>
          <div class="text-xl">The Not-So-Smart Factory</div>
        </div>
        <div>
          <img src="/pe_dumb_factory.png" class="picture" />
        </div>
      </div>
    </div>

    <div
      class="flex flex-1 flex-col-reverse items-center justify-center gap-12 lg:flex-row"
    >
      <div class="flex flex-1 flex-col items-center justify-center gap-2 text-center">
        <div>
          <div class="text-xl">The Smart Factory</div>
        </div>
        <div>
          <img src="/pe_smart_factory.png" class="picture" />
        </div>
      </div>

      <div class="text-block xxoutline">
        <p>Pocket-E: Smart Factory facilitates communication between:</p>

        <ul class="ml-8 list-disc">
          <li>Top Floor users</li>
          <li>ERPs (and other enterprise systems)</li>
          <li>Machines (via PLC connectivity)</li>
          <li>Shop Floor users</li>
        </ul>

        <p>We automate and integrate your entire enterprise in real-time.</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

import { useStore } from '@projroot/store';
const store = useStore();

onMounted(async () => {});
</script>

<style scoped lang="scss">
.xxoutline {
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.text-block {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.3rem;
  line-height: 1.2;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: white;
  font-weight: 500;
  max-width: 40rem;
  pointer-events: all;
  border-radius: 0.5rem;
  border: 0.1rem solid rgba(255, 255, 255, 0.5);
}

.isLarge {
  max-width: 70rem;
  .picture {
    max-width: 30rem;
    // object-fit: contain;
    // object-fit: scale-down;
  }
}

.isSmall {
  max-width: 95vw;
  .picture {
    width: 30rem;
    max-width: 80vw;
  }
}

/*
.picture {
  height: 30rem;
}
*/
</style>
