import { Ref } from 'vue';
import * as THREE from 'three';

import { NavState } from './state';

var pointer = new THREE.Vector2();

var camTarget = new THREE.Vector3(0, 0, 0);
var camTargetDestination = new THREE.Vector3(0, 0, 0);
var camDistance = 4;
var camSmoothFactor = 0.02; // smoothing factor, adjust for speed of movement
var camDestination = new THREE.Vector3(0, 0, camDistance);
// var camCraziness = 0.1;

let clock = new THREE.Clock();
let amplitude = 0.001; // amplitude of the oscilating movement
let frequency = 0.4; // frequency of the oscilating movement

let state: NavState = NavState.Welcome;

var scene: THREE.Scene;
var camera: THREE.PerspectiveCamera;
var renderer: THREE.WebGLRenderer;

let wirePlane: THREE.Mesh;
// let welcomeCube: THREE.Mesh;
let animationFrame: number;

let isLargeScreen: Ref<boolean>;

const pointSize = 0.05;
const width = 80;
const length = 160;

function generatePointCloudGeometry(color, width, length) {
  const geometry = new THREE.BufferGeometry();
  const numPoints = width * length;

  const positions = new Float32Array(numPoints * 3);
  const colors = new Float32Array(numPoints * 3);

  let k = 0;

  for (let i = 0; i < width; i++) {
    for (let j = 0; j < length; j++) {
      const u = i / width;
      const v = j / length;
      const x = u - 0.5;
      const y = (Math.cos(u * Math.PI * 4) + Math.sin(v * Math.PI * 8)) / 20;
      const z = v - 0.5;

      positions[3 * k] = x;
      positions[3 * k + 1] = y;
      positions[3 * k + 2] = z;

      const intensity = (y + 0.1) * 5;
      colors[3 * k] = color.r * intensity;
      colors[3 * k + 1] = color.g * intensity;
      colors[3 * k + 2] = color.b * intensity;

      k++;
    }
  }

  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
  geometry.computeBoundingBox();

  return geometry;
}

function generatePointcloud(color, width, length) {
  const geometry = generatePointCloudGeometry(color, width, length);
  const material = new THREE.PointsMaterial({ size: pointSize, vertexColors: true });

  return new THREE.Points(geometry, material);
}

function generateIndexedPointcloud(color, width, length) {
  const geometry = generatePointCloudGeometry(color, width, length);
  const numPoints = width * length;
  const indices = new Uint16Array(numPoints);

  let k = 0;

  for (let i = 0; i < width; i++) {
    for (let j = 0; j < length; j++) {
      indices[k] = k;
      k++;
    }
  }

  geometry.setIndex(new THREE.BufferAttribute(indices, 1));

  const material = new THREE.PointsMaterial({ size: pointSize, vertexColors: true });

  return new THREE.Points(geometry, material);
}

function generateIndexedWithOffsetPointcloud(color, width, length) {
  const geometry = generatePointCloudGeometry(color, width, length);
  const numPoints = width * length;
  const indices = new Uint16Array(numPoints);

  let k = 0;

  for (let i = 0; i < width; i++) {
    for (let j = 0; j < length; j++) {
      indices[k] = k;
      k++;
    }
  }

  geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  geometry.addGroup(0, indices.length);

  const material = new THREE.PointsMaterial({ size: pointSize, vertexColors: true });

  return new THREE.Points(geometry, material);
}

function addWirePlane() {
  if (!scene) return;

  // Load the hexagon texture
  const textureLoader = new THREE.TextureLoader();
  const hexagonTexture = textureLoader.load('bestagon.png');

  // Adjust the repeat for the texture
  hexagonTexture.wrapS = hexagonTexture.wrapT = THREE.RepeatWrapping;
  hexagonTexture.repeat.set(40, 40); // Adjust these numbers to control the tiling

  // Create the geometry for the plane
  const geometry = new THREE.PlaneGeometry(100, 100);

  // Create a material with the hexagon texture
  const material = new THREE.MeshBasicMaterial({
    map: hexagonTexture,
    color: 0x004400, // Darken the texture. Adjust this color to your preference
  });

  // Create the mesh using the geometry and material
  wirePlane = new THREE.Mesh(geometry, material);
  wirePlane.position.set(0, 0, -20);
  wirePlane.rotateX(-0.5);
  scene.add(wirePlane);
}

/*
function addWelcomeCube() {
  var texture = new THREE.TextureLoader().load('pe_logo.png');
  texture.colorSpace = THREE.SRGBColorSpace;
  var material = new THREE.MeshStandardMaterial({
    map: texture,
    color: 0xffffff,
  });
  var geometry = new THREE.BoxGeometry();
  welcomeCube = new THREE.Mesh(geometry, material);
  welcomeCube.rotateX(0.2);
  scene.add(welcomeCube);
}
*/

function addLights() {
  // var ambientLight = new THREE.AmbientLight(0xffffff, 0.01);
  // scene.add(ambientLight);

  var directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
  directionalLight.position.set(-1 * camDistance, 1, camDistance);
  scene.add(directionalLight);

  var directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
  directionalLight.position.set(0, 0, camDistance);
  scene.add(directionalLight);

  var directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
  directionalLight.position.set(-2, 2, camDistance);
  scene.add(directionalLight);
}

function handleMousemove(event: MouseEvent) {
  pointer.x = -(event.clientX / window.innerWidth) * 2 - 1;
  pointer.y = (event.clientY / window.innerHeight) * 2 + 1;

  /*
  var radius = camDistance; // Keep the same radius as before
  camDestination.x = camTarget.x + radius * Math.sin(mouse.x * camCraziness);
  camDestination.y = camTarget.y + radius * Math.sin(mouse.y * camCraziness);
  camDestination.z = camTarget.z + radius * Math.cos(mouse.x * camCraziness);
  */
}

function handleResize() {
  var width = window.innerWidth;
  var height = window.innerHeight;
  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  setDestinations();
}

function setDestinations() {
  const largeXOffset = -1;
  const smallYOffset = 0.5;
  if (state == NavState.Welcome) {
    camDestination.set(0, 0, camDistance);
    if (isLargeScreen.value) {
      camTargetDestination.set(0 + largeXOffset, 0, 0);
    } else {
      camTargetDestination.set(0, 0 + smallYOffset, 0);
    }
  }
  if (state == NavState.SmartFactory) {
    camDestination.set(0, 20, camDistance);
    if (isLargeScreen.value) {
      camTargetDestination.set(0 + largeXOffset, 20, 0);
    } else {
      camTargetDestination.set(0, 20 + smallYOffset, 0);
    }
  }
  if (state == NavState.UseCases) {
    camDestination.set(0, -20, camDistance);
    if (isLargeScreen.value) {
      camTargetDestination.set(0 + largeXOffset, -20, 0);
    } else {
      camTargetDestination.set(0, -20 + smallYOffset, 0);
    }
  }
  if (state == NavState.Consulting) {
    camDestination.set(-20, 0, camDistance);
    if (isLargeScreen.value) {
      camTargetDestination.set(-20 + largeXOffset, 0, 0);
    } else {
      camTargetDestination.set(-20, 0 + smallYOffset, 0);
    }
  }
  if (state == NavState.Efiato) {
    camDestination.set(20, 0, camDistance);
    if (isLargeScreen.value) {
      camTargetDestination.set(20 + largeXOffset, 0, 0);
    } else {
      camTargetDestination.set(20, 0 + smallYOffset, 0);
    }
  }
}

export function createScene(guts: HTMLElement, parent_isLargeScreen: Ref<boolean>) {
  if (!guts) {
    console.error('No app element found');
    return;
  }

  isLargeScreen = parent_isLargeScreen;

  document.addEventListener('mousemove', handleMousemove, false);

  // Scene, camera, and renderer
  scene = new THREE.Scene();
  camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    1000,
  );
  renderer = new THREE.WebGLRenderer({ antialias: true });

  handleResize();
  guts.appendChild(renderer.domElement);

  // Handle window resize
  window.addEventListener('resize', handleResize);

  addLights();
  addWirePlane();
  // addWelcomeCube();
  // addMiscObj();

  ///////////////////////////
  let scale = 1.0;
  let xpos = -4;
  let ypos = 1;
  let zpos = -3;
  let xrot = 0.9;
  const pcBuffer = generatePointcloud(new THREE.Color(1, 0, 0), width, length);
  pcBuffer.scale.set(5 * scale, 10 * scale, 10 * scale);
  pcBuffer.position.set(-5 + xpos, ypos, zpos);
  pcBuffer.rotateX(xrot);
  scene.add(pcBuffer);

  const pcIndexed = generateIndexedPointcloud(new THREE.Color(0, 1, 0), width, length);
  pcIndexed.scale.set(5 * scale, 10 * scale, 10 * scale);
  pcIndexed.position.set(0 + xpos, ypos, zpos);
  pcIndexed.rotateX(xrot);
  scene.add(pcIndexed);

  const pcIndexedOffset = generateIndexedWithOffsetPointcloud(
    new THREE.Color(0, 1, 1),
    width,
    length,
  );
  pcIndexedOffset.scale.set(5 * scale, 10 * scale, 10 * scale);
  pcIndexedOffset.position.set(5 + xpos, ypos, zpos);
  pcIndexedOffset.rotateX(xrot);
  scene.add(pcIndexedOffset);

  ///////////////////////////

  camera.position.set(camDestination.x, camDestination.y, camDestination.z);
  camera.lookAt(camTarget);

  animate();
}

function animate() {
  animationFrame = requestAnimationFrame(animate);
  // cube.rotation.x += 0.01;
  // welcomeCube.rotation.y += 0.002;

  // move our camera toward our destination
  camera.position.lerp(camDestination, camSmoothFactor);

  // slightly move our camera around so it's never 100% stationary
  let elapsedTime = clock.getElapsedTime();
  camera.position.x += amplitude * Math.sin(frequency * elapsedTime);
  camera.position.y += amplitude * Math.cos(frequency * elapsedTime);

  camTarget.lerp(camTargetDestination, camSmoothFactor * 2);
  camera.lookAt(camTarget);

  renderer.render(scene, camera);
}

export function setSceneState(newState: NavState) {
  state = newState;
  setDestinations();
}

export function cleanupScene() {
  cancelAnimationFrame(animationFrame);
  document.removeEventListener('mousemove', handleMousemove);
  window.removeEventListener('resize', handleResize);
}
