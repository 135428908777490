<template>
  <ValidatedFormField :name="name" :label="label" :validationField="validationField">
    <slot>
      <InputDate v-model="internalValue" :name="name" v-bind="$attrs" />
    </slot>
  </ValidatedFormField>
</template>

<script setup lang="ts">
import { PropType, computed, WritableComputedRef } from 'vue';
import { BaseValidation } from '@vuelidate/core';
import ValidatedFormField from '../ValidatedFormField';
import InputDate from '@components/InputDate';

const props = defineProps({
  modelValue: {
    type: [String, Date],
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  validationField: {
    type: Object as PropType<BaseValidation>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const internalValue: WritableComputedRef<string> = computed({
  get: (): string => props.modelValue as string,
  set(value: string) {
    emit('update:modelValue', value);
  },
});
</script>

<style scoped></style>
