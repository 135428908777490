import { defineStore } from 'pinia';
import { actions } from './actions';
import { State, stateObj } from './state';

const usePEStore = defineStore({
  id: `${import.meta.env.VITE_ENVNAME}:${import.meta.env.VITE_BUILDDIR}`,
  state: () => stateObj() as State,
  actions: {
    ...actions,
    // define custom actions here
  },
  persist: true,
});

export function useStore() {
  return usePEStore();
}
