<template>
  <div>
    <template v-if="!store.$state.isLargeScreen">
      <div class="toggler" :class="{ checked: checked }" @click="checked = !checked" />
      <div class="hamburger"><div></div></div>
    </template>
    <Transition name="slide-fade">
      <div class="nav-outer flex items-center justify-center">
        <div class="nav flex justify-center">
          <div class="left mr-6 flex items-center">
            <div
              class="option"
              @mouseenter="if (state != NavState.Welcome) homeHover = true;"
              @mouseleave="homeHover = false"
              @click="handleClick(NavState.Welcome)"
            >
              <img class="icon" src="/pe_logo.png" />
            </div>
          </div>
          <div class="right relative flex justify-between gap-4">
            <div
              class="option"
              :class="{
                hover: smartFactoryHover,
                selected: state == NavState.SmartFactory,
              }"
              @mouseenter="
                if (state != NavState.SmartFactory) smartFactoryHover = true;
              "
              @mouseleave="smartFactoryHover = false"
              @click="handleClick(NavState.SmartFactory)"
            >
              <div class="label">Smart Factory</div>
            </div>
            <div
              class="option"
              :class="{ hover: useCasesHover, selected: state == NavState.UseCases }"
              @mouseenter="if (state != NavState.UseCases) useCasesHover = true;"
              @mouseleave="useCasesHover = false"
              @click="handleClick(NavState.UseCases)"
            >
              <div class="label">Use Cases</div>
            </div>
            <div
              class="option"
              :class="{ hover: efiatoHover, selected: state == NavState.Efiato }"
              @mouseenter="if (state != NavState.Efiato) efiatoHover = true;"
              @mouseleave="efiatoHover = false"
              @click="handleClick(NavState.Efiato)"
            >
              <div class="label">Efiato</div>
            </div>
            <div
              class="option"
              :class="{
                hover: consultingHover,
                selected: state == NavState.Consulting,
              }"
              @mouseenter="if (state != NavState.Consulting) consultingHover = true;"
              @mouseleave="consultingHover = false"
              @click="handleClick(NavState.Consulting)"
            >
              <div class="label">Consulting</div>
            </div>
            <div class="bubble" ref="bubble"></div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, nextTick } from 'vue';
import { NavState } from './state';

import { useStore } from '@projroot/store';
const store = useStore();

const state = defineModel<NavState>();

const homeHover = ref<boolean>(false);
const smartFactoryHover = ref<boolean>(false);
const useCasesHover = ref<boolean>(false);
const consultingHover = ref<boolean>(false);
const efiatoHover = ref<boolean>(false);

const bubble = ref<HTMLElement>();

onMounted(async () => {});

const emit = defineEmits(['navClick']);

// const hideNav = ref(false);

const checked = ref(store.$state.isLargeScreen);

async function handleClick(clickedState: NavState) {
  if (state.value != clickedState && !store.$state.doingNavAnimation) {
    store.$patch({ doingNavAnimation: true });
    emit('navClick', clickedState);
  }
  /*
  if (state.value != clickedState && !store.$state.doingNavAnimation) {
    store.$patch({ doingNavAnimation: true });
    state.value = clickedState;
    setTimeout(() => {
      store.$patch({ doingNavAnimation: false });
    }, 1000);
  }
  */
}

watch(state, async () => {
  homeHover.value = false;
  smartFactoryHover.value = false;
  useCasesHover.value = false;
  consultingHover.value = false;
  efiatoHover.value = false;

  checked.value = store.$state.isLargeScreen && state.value == NavState.Welcome;

  function moveBubbleToActiveItem(activeItem) {
    if (!bubble.value) return;
    const rect = activeItem.getBoundingClientRect();
    const parentRect = activeItem.parentNode.getBoundingClientRect();
    // bubble.value.style.top = `${rect.top - parentRect.top}px`;
    // bubble.value.style.height = `{rect.height}px`;
    bubble.value.style.bottom = `${rect.bottom - parentRect.bottom}px`;
    bubble.value.style.left = `${rect.left - parentRect.left}px`;
    bubble.value.style.width = `${rect.width}px`;
  }

  if (!bubble.value) return;
  await nextTick();
  const activeItem = document.querySelector('.option.selected');
  if (activeItem) {
    bubble.value.style.visibility = 'visible';
    moveBubbleToActiveItem(activeItem);
  } else {
    bubble.value.style.visibility = 'hidden';
    bubble.value.style.bottom = '0';
    bubble.value.style.left = '0';
    bubble.value.style.width = `1px`;
  }

  /*
  hideNav.value = true;
  setTimeout(() => {
    hideNav.value = false;
  }, 2000);
  */
});
</script>

<style scoped lang="scss">
.toggler {
  z-index: 2;
  height: 5rem;
  width: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 0;
  pointer-events: all;
}

.hamburger {
  position: absolute;
  top: 0;
  left: 0;
  height: 5rem;
  width: 5rem;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hamburger > div {
  position: relative;
  top: 0;
  left: 0;
  background: white;
  height: 2px;
  width: 60%;
  transition: all 0.4s ease;
  z-index: 2;
}

.hamburger > div::before,
.hamburger > div::after {
  content: '';
  position: absolute;
  top: -10px;
  background: white;
  width: 100%;
  height: 2px;
  transition: all 0.4s ease;
}

.hamburger > div::after {
  top: 10px;
}

.toggler.checked + .hamburger > div {
  background: rgba(0, 0, 0, 0);
}

.toggler.checked + .hamburger > div::before {
  top: 0;
  transform: rotate(45deg);
  background: white;
}

.toggler.checked + .hamburger > div::after {
  top: 0;
  transform: rotate(135deg);
  background: white;
}

/*
.menu {
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: min-content;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  pointer-events: all;

  .option {
    position: relative;

    &.selected {
      // border: 1px solid orange;
      background-color: rgb(27, 27, 27);
    }

    &.hover {
      cursor: pointer;
      border: none !important;
      .label {
        font-size: 1.4rem !important;
      }
      .icon {
        height: 3.5rem !important;
        width: 3.5rem !important;
      }
    }

    .guts {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      color: rgb(255, 255, 255);
      text-decoration: none;
      font-size: 1.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .label {
        transition: all 0.2s;
        font-size: 1.2rem;
      }
      .icon {
        transition: all 0.2s;
        height: 3rem;
        width: 3rem;
      }
    }
  }
}
*/

.nav-outer {
  /*
  position: absolute;
  top: 0;
  left: 0;
  */
  width: 100%;
  background-color: rgba(0, 0, 0);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  pointer-events: all;

  .bubble {
    height: 0.2rem;
    position: absolute;
    visibility: hidden;
    background-color: #005387;
    border-radius: 0.2rem;
    transition: all 0.3s ease;
  }

  .nav {
    max-width: 100rem;
    width: 100%;
    padding: 0.3rem 0.5rem;
    .left {
      img {
        border: 0.2rem solid #3377a100;
        height: 2.4rem;
        width: 2.4rem;
        transition: all 0.2s;
        cursor: pointer;
        &:hover {
          border: 0.2rem solid #005387cc;
        }
      }
    }
    .right {
      .option {
        display: flex;
        align-items: center;
        text-align: right;
        color: white;
        padding: 0.5rem;
        border-radius: 0.3rem;
        transition: all 0.2s;
        cursor: pointer;
        .label {
          font-size: 1.2rem !important;
        }
        .icon {
          height: 3.5rem !important;
          width: 3.5rem !important;
        }
        &.hover {
          background-color: #00538780;
        }
        &.selected {
          background-color: #00538799;
        }
      }
    }
  }
}

main {
  color: white;
}

.isSmall {
  height: 100% !important;
  background: rgba(0, 0, 0, 0.97);
  .selected {
    display: none;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
