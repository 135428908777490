<template>
  <PrimeVueCheckbox v-bind="combinedThings" v-on="eventRelays" :pt="pt" />
</template>

<script setup lang="ts">
import { useAttrs, computed } from 'vue';

import PrimeVueCheckbox from 'primevue/checkbox';
import CheckboxESM from 'primevue/checkbox/checkbox.esm.js';

import { ExtendedProps } from './types';

const props = defineProps<ExtendedProps>();

const attrs = useAttrs(); // useAttrs just includes things that ARE NOT props

const combinedThings = computed(() => ({
  ...props,
  ...attrs,
  binary: true, // all checkboxes should be binary
}));

const emit = defineEmits<{
  (event: string, ...args: any[]): void;
}>();

// setup relays on all our emits
const eventRelays = CheckboxESM.emits.reduce(
  (listeners: Record<string, Function>, key: string) => {
    listeners[key] = (...args: any[]) => {
      emit(key, ...args);
    };
    return listeners;
  },
  {},
);

const pt = computed(() => {
  if (props.slsMode === 'big') {
    return {
      root: {
        class: ['!w-[53px]', '!h-[53px]'],
      },
      box: {
        class: ['!w-[53px]', '!h-[53px]'],
      },
      icon: {
        class: ['!w-[40px]', '!h-[40px]'],
      },
    };
  }
  return {};
});
</script>
