import { MRPReportGuts } from './mrpreport';

export default class RevisionedStore {
  //@ts-ignore
  version: number = new Date(__BUILD_DATE__).getTime();
}

export class State extends RevisionedStore {
  theme: string = 'light';
  primaryColor: string = 'blue';
  surfaceColor: string = 'gray1';
  loginUri: string = '';
  auth = {
    profile: {},
    token: '',
    idToken: '',
    expiresAt: 0,
  };
  subscription = {
    status: '',
    id: '',
  };
  scheduled_downtime_acknowledgement: { [key: number]: number } = {};
  initial_tour: boolean = false;
  mrpReport: MRPReportGuts = {
    company: '',
    group: '',
    partFrom: '',
    partTo: '',
    lineFrom: '',
    lineTo: '',
  };
}

export function stateObj() {
  const newState = new State();
  return { ...newState };
}
