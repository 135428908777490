import { ColDef, ColType, TableClass } from '@service/gql';

export enum FilterOperator {
  AND = '_and',
  OR = '_or',
}
export enum FilterMatchMode {
  STARTS_WITH = '_like',
  CONTAINS = '_ilike',
  NOT_CONTAINS = '_nilike',
  EQUALS = '_eq',
  NOT_EQUALS = '_neq',
  IN = '_in',
  LESS_THAN = '_lt',
  LESS_THAN_OR_EQUAL_TO = '_lte',
  GREATER_THAN = '_gt',
  GREATER_THAN_OR_EQUAL_TO = '_gte',
  DATE_IS = '_eq',
  DATE_IS_NOT = '_neq',
  DATE_BEFORE = '_lt',
  DATE_AFTER = '_gt',
  IS_NULL = '_is_null',
}

// Determine what filter selection options are available
//  for each ColType
export function filterMatchOptions<T extends TableClass>(column: ColDef<T>) {
  if (column.column_type == ColType.float || column.column_type == ColType.int)
    return [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS },
      { label: 'Less Than', value: FilterMatchMode.LESS_THAN },
      {
        label: 'Less Than or Equal',
        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
      },
      { label: 'Greater Than', value: FilterMatchMode.GREATER_THAN },
      {
        label: 'Greater Than or Equal',
        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      },
    ];
  if (column.column_type == ColType.bool || column.choices)
    return [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      // { label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS },
    ];
  if (column.column_type == ColType.datetime || column.column_type == ColType.date)
    return [
      { label: 'Date Is', value: FilterMatchMode.DATE_IS },
      { label: 'Date Is Not', value: FilterMatchMode.DATE_IS_NOT },
      { label: 'Date Before', value: FilterMatchMode.DATE_BEFORE },
      { label: 'Date After', value: FilterMatchMode.DATE_AFTER },
    ];
  return [
    // { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
    // { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Not Contains', value: FilterMatchMode.NOT_CONTAINS },
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS },
    { label: 'Less Than', value: FilterMatchMode.LESS_THAN },
    {
      label: 'Less Than or Equal',
      value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
    },
    { label: 'Greater Than', value: FilterMatchMode.GREATER_THAN },
    {
      label: 'Greater Than or Equal',
      value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    },
  ];
}

export function getDefaultOption<T extends TableClass>(column: ColDef<T>) {
  if (column.column_type == ColType.float || column.column_type == ColType.int)
    return FilterMatchMode.EQUALS;
  if (column.column_type == ColType.bool || column.choices)
    return FilterMatchMode.EQUALS;
  if (column.column_type == ColType.datetime || column.column_type == ColType.date)
    return FilterMatchMode.DATE_IS;
  return FilterMatchMode.CONTAINS;
}

export function getDefaultValue<T extends TableClass>(column: ColDef<T>) {
  if (column.column_type == ColType.float || column.column_type == ColType.int)
    return 0;
  if (column.column_type == ColType.bool) return true;
  if (column.column_type == ColType.datetime || column.column_type == ColType.date)
    return new Date();
  if (column.choices && column.choices.length > 0) return column.choices[0];
  return '';
}

// Determine what filter operator options are available
export function filterOperatorOptions() {
  return [
    { label: 'Match All', value: FilterOperator.AND },
    { label: 'Match Any', value: FilterOperator.OR },
  ];
}
