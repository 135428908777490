export enum CategoryEnum {
  AEROSPACE_AND_DEFENCE = 'AEROSPACE_AND_DEFENCE',
  AUTOMOTIVE = 'AUTOMOTIVE',
  CHEMICALS = 'CHEMICALS',
  CONSUMER_GOODS = 'CONSUMER_GOODS',
  ELECTRONICS = 'ELECTRONICS',
  ENERGY = 'ENERGY',
  FOOD_AND_BEVERAGE = 'FOOD_AND_BEVERAGE',
  METALS = 'METALS',
  PHARMACEUTICALS = 'PHARMACEUTICALS',
  TEXTILES = 'TEXTILES',
  OTHER = 'OTHER',
}
export enum InpEventTypeEnum {
  PROD = 'PROD',
  SCRP = 'SCRP',
  RWRK = 'RWRK',
  RJCT = 'RJCT',
  DOWN = 'DOWN',
}
export enum PriorityEnum {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}
export enum SubStatusEnum {
  INITIALIZING = 'INITIALIZING',
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  UNPAID = 'UNPAID',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  TRIALING = 'TRIALING',
}
export enum SubscriptionEnum {
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  ENTERPRISE = 'ENTERPRISE',
}
export enum TypeAf5Enum {
  SCRP = 'SCRP',
  RWRK = 'RWRK',
  RJCT = 'RJCT',
  DOWN = 'DOWN',
}
