<template>
  <ValidatedFormField :name="name" :label="label" :validationField="validationField">
    <slot>
      <InputDateTime
        v-model="internalValue"
        :name="name"
        :hasSeconds="hasSeconds"
        :disabled="disabled"
      />
    </slot>
  </ValidatedFormField>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ComputedRef } from 'vue';
import { BaseValidation } from '@vuelidate/core';
import ValidatedFormField from '../ValidatedFormField';
import InputDateTime from '@components/InputDateTime';

export default defineComponent({
  components: {
    ValidatedFormField,
    InputDateTime,
  },
  props: {
    modelValue: {
      type: Date,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validationField: {
      type: Object as PropType<BaseValidation>,
      required: true,
    },
    hasSeconds: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { attrs, slots, emit }) {
    const internalValue: ComputedRef<Date> = computed({
      get: (): Date => props.modelValue as Date,
      set(value: Date) {
        emit('update:modelValue', value);
      },
    });
    return { internalValue };
  },
});
</script>

<style scoped></style>
