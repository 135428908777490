import { Ref } from 'vue';
// import API from '@custom/base/api';
import { GQLState, TableClass } from '@service/gql';
// import { isEfiato, getStoreKey } from '@service/helpers';
// import { generateGuid } from '@composables/generate_guid';
// import { getSessionKey } from './misc';

export async function downloadXlsx(gqlState: Ref<GQLState<TableClass>>, core) {
  /* TODO***
  const efiato: boolean = isEfiato();

  const gqlStateDeRef = JSON.parse(JSON.stringify(gqlState));

  const jobId = generateGuid();
  let state = {};

  const filters: any[] = [];
  if (tableParams.filters) {
    let state = tableParams.filters;

    if (Object.keys(state).length) {
      filters.push(state);
    }
  }
  state['filter'] = filters;

  state['sort'] = {
    sortField: tableParams.sortField,
    sortOrder: tableParams.sortOrder,
  };

  const fields: string[] = [];
  const headers: string[] = [];
  const cols = tableParams.columns;
  if (cols) {
    for (const field of cols) {
      if (!field['hidden'] && field['column_type'] != 'hidden') {
        fields.push(field['field']);
        if (field['header']) {
          headers.push(field['header']);
        } else {
          headers.push(field['field']);
        }
      }
    }
  }

  let merged = mergeWhere(gqlStateDeRef.where, where);
  gqlStateDeRef.where = merged;

  let nameOfTable = tableState.tableName;
  if (!nameOfTable) nameOfTable = tableName;

  const payload = {
    local_tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    gql_state: {
      where: gqlStateDeRef.where,
      order_by: gqlStateDeRef.order_by,
    },
    table_state: {
      tableName: nameOfTable,
      columns: tableState.columns,
    },
    col_filters: {
      rows: fields,
      headers: headers,
    },
  };

  console.log('payload', payload);

  let protocol = 'ws';
  if (import.meta.env.VITE_BACKEND_SSL == 1) protocol = 'wss';
  const baseURL = protocol + '://' + import.meta.env.VITE_BACKEND_SERVER;
  var endpoint = baseURL + '/async/security/xlsx';
  if (efiato) endpoint = baseURL + '/ws/xlsx';
  var socket = new WebSocket(endpoint);

  socket.onmessage = function (e) {
    console.log('message', e);
    const url = window.URL.createObjectURL(new Blob([e.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.xlsx');
    document.body.appendChild(link);
    link.click();
    socket.close();
    core.overlay.hide();
  };

  socket.onopen = function async(e) {
    console.log('open', e);
    socket.send(jobId);

    // now actually request our job once our socket is listening
    // (if we hit unreliability again, rig up a round-trip flow
    //   where the server response to our 'jobId' message and THEN
    //   we send the job request)
    let url = '/backend/v1/gob/queue_gob/';
    if (efiato) url = '/v1/navutil/gob/queue_gob/';

    const pinia = window.localStorage.getItem(getStoreKey());
    let domain = 'N/A';
    let session = 'N/A';
    if (pinia) {
      domain = JSON.parse(pinia)['domain'];
      session = JSON.parse(pinia)['session'];
    }

    const params = {
      uid: jobId,
      payload: JSON.stringify(payload),
      domain: domain,
      session: session,
    };

    API.post(url, params);
  };

  socket.onerror = function (e) {
    console.log('error', e);
    core.overlay.hide();
  };

  socket.onclose = function (e) {
    console.log('close', e);
    core.overlay.hide();
  };
    */
}
