import { computed, ref } from 'vue';
import axios from 'axios';
import moment from 'moment';
import FileSaver from 'file-saver';
import { ColDef, TableClass } from './gql';
import * as XLSX from 'xlsx';
import { useStore } from '@custom/base/store';
import { SubStatusEnum } from '../generated/oapi.enums';

export const hideFluff = Number(import.meta.env.VITE_HIDE_FLUFF) == 1;

// DONT USE THIS ANYMORE (delete once remove old usages)
export const dateFormat = (str) => {
  return moment(str).format('YYYY-MM-DD HH:mm');
};

// USE THIS!
export const datetimeDisplayFormat = (val: string | Date, padZeros?: boolean) => {
  if (!val) return '';
  const piniaStore = useStore();

  // Ensure we're working with a Date object
  if (typeof val === 'string') val = new Date(val);

  // Define options for formatting
  const options = padZeros
    ? {
        timeZone: piniaStore.timezone,
        year: 'numeric' as const,
        month: '2-digit' as const,
        day: '2-digit' as const,
        hour: '2-digit' as const,
        minute: '2-digit' as const,
        second: '2-digit' as const,
        hour12: false,
      }
    : {
        timeZone: piniaStore.timezone,
      };

  // Apply the timezone and return the formatted string
  return val.toLocaleString(getLocale(), options);
};

export const dateDisplayFormat = (val: string | Date) => {
  if (!val) return '';
  // Ensure we're working with a Date object
  if (typeof val === 'string') val = new Date(val);

  // Adjust to UTC
  const utcDate = new Date(val.getUTCFullYear(), val.getUTCMonth(), val.getUTCDate());

  // Manually construct the date string in "M/D/YYYY" format
  const month = utcDate.getMonth() + 1; // getMonth() returns month index starting from 0
  const day = utcDate.getDate();
  const year = utcDate.getFullYear();

  return `${month}/${day}/${year}`;
};

export const safeDate = (val: string | Date): Date => {
  if (typeof val === 'string') return new Date(Date.parse(val));
  return new Date(val);
};

export function splitDataOnBreak(data) {
  if (!data) return [];
  if (typeof data !== 'string') return [data];
  if (data.indexOf('<br>') >= 0) {
    const splitArray = data.split(/<br>/);
    return splitArray.filter((str) => str !== undefined);
  }
  const splitArray = data.split(/\n/);
  return splitArray.filter((str) => str !== undefined);
}

export const getLocale = () => {
  return new Intl.NumberFormat().resolvedOptions().locale;
};

// Public Domain/MIT
export function generateUUID() {
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const exportToSpreadsheet = (data, fileName, sheetname = 'Sheet') => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // Desired file extesion
  const fileExtension = '.xlsx';

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetname);

  // Exporting the file with the desired name and extension.
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, fileName + fileExtension);
};

export function convertSeconds(sec: number) {
  let negative = false;
  if (sec < 0) negative = true;

  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  let shours = hours.toString();
  let sminutes = minutes.toString();
  let sseconds = seconds.toString();
  // add 0 if value < 10; Example: 2 => 02
  let res = '';
  if (hours > 0) {
    res += shours + ':';
  }
  if (hours > 0 && minutes < 10) {
    sminutes = '0' + sminutes;
  }
  res += sminutes + ':';
  if (seconds < 10) {
    sseconds = '0' + sseconds;
  }
  res += sseconds;

  if (negative) res = '-' + res;

  return res;
}

export function secondsToTimeFormat(e: number) {
  const h = Math.floor(e / 3600)
    .toString()
    .padStart(2, '0');
  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, '0');

  return h + ':' + m + ':' + s;
}

export function subscriptionIsActive(status: String) {
  if ([SubStatusEnum.ACTIVE, SubStatusEnum.TRIALING].includes(status as any)) {
    return true;
  }
  return false;
}

export function parseAPIError(e: unknown) {
  console.log(e);
  var msg = 'API Error';
  if (axios.isAxiosError(e) && e.response) {
    if (e.response.status == 404) {
      return e.message;
    }

    if ((e.response.data as any)['detail']) {
      msg = (e.response.data as any)['detail'];
    }

    // catch a straight 500 error response text
    else if (typeof e.response.data === 'string' || e.response.data instanceof String) {
      msg = e.response.data as string;
      console.log(msg);
      if (msg.indexOf('violates unique constraint') >= 0) {
        /* 500: duplicate key value violates unique constraint "..."
           DETAIL:  Key (_domain_id, kanban)=(1, K0005778) already exists.
        */
        msg = msg.split('DETAIL: ')[1];
      }
    }

    // catch field-specific error (saying a field is required or etc)
    else if (Object.keys(e.response.data as any).length) {
      const key = Object.keys(e.response.data as any)[0];
      msg = `${(e.response.data as any)[key][0]}: ${key}`;
      msg = msg.toLocaleUpperCase();
    }
  }
  return msg;
}

export function isEfiato(): boolean {
  return import.meta.env.VITE_ENVNAME == 'EFIATO';
}

export function getStoreKey(): string {
  return `${import.meta.env.VITE_ENVNAME}:${import.meta.env.VITE_BUILDDIR}`;
}

export function setStorage(
  unique_pfx: string,
  key: string,
  val: number | boolean | string,
) {
  localStorage.setItem(`${unique_pfx}⦀${key}`, val.toString());
}

export function getStorageStr(
  unique_pfx: string,
  key: string,
  defVal?: string,
): string {
  const storage = localStorage.getItem(`${unique_pfx}⦀${key}`);
  if (storage) return storage;
  if (defVal !== undefined) return defVal;
  return '';
}

export function getStorageInt(
  unique_pfx: string,
  key: string,
  defVal?: number,
): number {
  const storage = localStorage.getItem(`${unique_pfx}⦀${key}`);
  if (storage) return parseInt(storage);
  if (defVal !== undefined) return defVal;
  return 0;
}

export function getStorageBool(
  unique_pfx: string,
  key: string,
  defVal?: boolean,
): boolean {
  const storage = localStorage.getItem(`${unique_pfx}⦀${key}`);
  if (storage) return storage === 'true';
  if (defVal !== undefined) return defVal;
  return false;
}

export function slugify(str) {
  return str
    .toLowerCase() // Convert to lower case
    .trim() // Trim leading and trailing spaces
    .replace(/[^a-z0-9 -]/g, '') // Remove invalid chars
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/-+/g, '-'); // Replace multiple - with single -
}

export function toTitleCase(str: string): string {
  return str.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function getColDefHeader<T extends TableClass>(colDef: ColDef<T>): string {
  if (colDef.header) {
    return colDef.header;
  }

  if (colDef.field === 'id') {
    return 'ID';
  }

  const withSpaces = colDef.field
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ');
  return toTitleCase(withSpaces);
}

export function getColDefFormatedFloat<T extends TableClass>(
  column: ColDef<T>,
  val: any,
) {
  if (!column.decimals) return val;

  let nbr = val ? parseFloat(val) : val;
  if (Number.isNaN(nbr)) return val;

  const multiplier = 10 ^ column.decimals;
  nbr = (nbr * multiplier) / multiplier;
  return nbr.toLocaleString(undefined, {
    minimumFractionDigits: column.decimals,
    maximumFractionDigits: column.decimals,
    useGrouping: true,
  });
}

export const isAdminOrManager = (role: string) => {
  return role === 'ADMIN' || role === 'MANAGER';
};

export function createComputed(obj, key) {
  return computed({
    get() {
      return obj[key];
    },
    set(value) {
      obj[key] = value;
    },
  });
}
