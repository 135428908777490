<template>
  <div
    class="absolute left-0 top-0 flex h-full w-full flex-col"
    style="
      z-index: 2;
      background-color: none;
      pointer-events: all;
      background-color: black;
    "
  >
    <NavLamest
      v-if="store.$state.isLargeScreen"
      v-model="state"
      @navClick="handleNavClick"
    />
    <div
      ref="scroller"
      class="relative h-full flex-1 text-white"
      style="overflow-y: scroll"
    >
      <div id="bod" class="flex w-full flex-col text-white">
        <section
          id="Welcome"
          class="flex justify-center"
          :style="{ height: sectionHeight }"
          ref="welcomeSection"
        >
          <div
            class="absolute left-0 top-0 h-full w-full"
            ref="guts"
            style="z-index: -1; overflow: hidden"
          ></div>
          <Welcome />
        </section>

        <section
          id="SmartFactory"
          class="flex flex-col items-center justify-center"
          :style="{ 'min-height': isLargeScreen ? sectionHeight : 'auto' }"
          style="background-color: rgb(24, 24, 24)"
          ref="smartFactorySection"
        >
          <SmartFactory />
        </section>

        <section
          id="UseCases"
          class="flex flex-col items-center justify-center"
          :style="{ 'min-height': isLargeScreen ? sectionHeight : 'auto' }"
          ref="useCasesSection"
        >
          <UseCases />
        </section>

        <section
          id="Efiato"
          class="flex flex-col items-center justify-center"
          :style="{ 'min-height': isLargeScreen ? sectionHeight : 'auto' }"
          ref="efiatoSection"
        >
          <Efiato />
        </section>

        <section
          id="Consulting"
          class="flex flex-col items-center justify-center"
          :style="{ 'min-height': isLargeScreen ? sectionHeight : 'auto' }"
          ref="consultingSection"
        >
          <Consulting />
        </section>

        <footer
          class="flex flex-col-reverse items-center justify-center gap-2 px-2 py-1 text-xs font-bold md:flex-row"
          style="color: rgba(255, 255, 255, 0.361)"
        >
          <div>
            ©
            <a href="https://semi-legitimate.com" target="_blank"
              >Semi-Legitimate Software, LLC.</a
            >
            All rights reserved.
          </div>
          <div class="hidden md:block">|</div>
          <div class="flex gap-2">
            <div class="cursor-pointer" @click="showLegal = true">Legal</div>
            <div>|</div>
            <div class="cursor-pointer" @click="showPrivacy = true">Privacy</div>
          </div>
        </footer>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="showLegal"
    header="Legal"
    :breakpoints="{ '900px': '75vw', '640px': '100vw' }"
    :style="{ width: '40rem' }"
    :draggable="true"
    :keepInViewPort="true"
    :minX="0"
    :minY="0"
    :modal="true"
    :closable="true"
  >
    <div class="flex flex-col gap-2 px-6 py-2 text-left">
      <div class="text-lg font-bold">semi-legitimate.com</div>
      <div>
        "industrial remains" photo by Till Krech licensed under CC Attribution 2.0
        Generic
      </div>
      <div class="mt-2 text-lg font-bold">slsgroup.org</div>
      <div>
        "Glass and Steel (HONG KONG/ARCHITECTURE) IX" photo by Chi King licensed under
        CC Attribution 2.0 Generic
      </div>
      <div>
        "jefferson ave" photo by pal bica licensed under CC Attribution 2.0 Generic /
        Slight Blur and Crop Added
      </div>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="showPrivacy"
    header="Privacy"
    :breakpoints="{ '900px': '75vw', '640px': '100vw' }"
    :style="{ width: '40rem' }"
    :draggable="true"
    :keepInViewPort="true"
    :minX="0"
    :minY="0"
    :modal="true"
    :closable="true"
  >
    <div class="flex flex-col gap-2 px-6 py-2 text-left">
      <iframe src="/pe_privacy.html" class="min-h-60 bg-white" />
    </div>
  </Dialog>
  <Contact
    class="absolute left-0 top-0 flex h-full w-full"
    style="z-index: 3; background-color: none; pointer-events: none"
  />
</template>

<script setup lang="ts">
import { onMounted, ref, watch, onUnmounted, computed, PropType } from 'vue';

import { storeToRefs } from 'pinia';

import { createScene, setSceneState, cleanupScene } from './scene';

import { NavState, SpecialState } from './state';

import NavLamest from './NavLamest.vue';
import Contact from './Contact.vue';

import Welcome from './mode/Welcome.vue';
import SmartFactory from './mode/SmartFactory.vue';
import UseCases from './mode/UseCase.vue';
import Efiato from './mode/Efiato.vue';
import Consulting from './mode/Consulting.vue';

import { useStore } from '@projroot/store';

const showLegal = ref(false);
const showPrivacy = ref(false);

const props = defineProps({
  loadState: {
    type: String as PropType<NavState>,
    required: true,
  },
  loadSpecialState: {
    type: String as PropType<SpecialState>,
    default: undefined,
  },
});
const scroller = ref<HTMLElement>();

const welcomeSection = ref<HTMLElement>();
const smartFactorySection = ref<HTMLElement>();
const useCasesSection = ref<HTMLElement>();
const efiatoSection = ref<HTMLElement>();
const consultingSection = ref<HTMLElement>();

const calculateMostVisibleSection = () => {
  let maxVisibleHeight = 0;
  let mostVisibleSection: any | null = null;

  function checkSection(section) {
    if (!scroller.value) return;
    if (section.value) {
      const { top, bottom } = section.value.getBoundingClientRect();
      const visibleHeight =
        Math.min(bottom, scroller.value.clientHeight) - Math.max(top, 0);

      if (visibleHeight > maxVisibleHeight) {
        maxVisibleHeight = visibleHeight;
        mostVisibleSection = section.value;
      }
    }
  }

  checkSection(welcomeSection);
  checkSection(smartFactorySection);
  checkSection(useCasesSection);
  checkSection(efiatoSection);
  checkSection(consultingSection);

  return mostVisibleSection;
};

const onScroll = () => {
  if (!store.$state.doingNavAnimation) {
    const mostVisible = calculateMostVisibleSection();
    if (mostVisible) state.value = mostVisible.id as NavState;
  }
};

function handleNavClick(clicked: NavState) {
  state.value = clicked;
  const interval = setInterval(() => {
    const mostVisible = calculateMostVisibleSection();
    if (mostVisible) {
      if (mostVisible.id == clicked) {
        store.$patch({ doingNavAnimation: false });
        clearInterval(interval);
      }
    }
  }, 100);
}

const store = useStore();

const state = ref(NavState.Welcome);

const { isLargeScreen } = storeToRefs(store);

const sectionHeight = computed(() => {
  const height = scroller.value ? scroller.value.clientHeight * 0.75 : 100;
  return `${height + 1}px`;
});

watch(state, (cur, old) => {
  /*
  if (cur == NavState.SmartFactory) slideName.value = 'upslide';
  if (cur == NavState.UseCases) slideName.value = 'downslide';
  if (cur == NavState.Consulting) slideName.value = 'leftslide';
  if (cur == NavState.Efiato) slideName.value = 'rightslide';
  else {
    // these are going 'back' to welcome
    if (old == NavState.SmartFactory) slideName.value = 'downslide';
    if (old == NavState.UseCases) slideName.value = 'upslide';
    if (old == NavState.Consulting) slideName.value = 'rightslide';
    if (old == NavState.Efiato) slideName.value = 'leftslide';
  }
  */
  const section = document.getElementById(cur);
  /*
  alert('section: ' + section);
  if (section) {
    section.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    setTimeout(() => {
      section.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    }, 100);
  } else showLegal.value = true;
  */

  if (section) section.scrollIntoView({ behavior: 'smooth' });
  setSceneState(cur);
});

const guts = ref<HTMLElement>();

onMounted(async () => {
  if (!guts.value) {
    console.error('No app element found');
    return;
  }
  if (!scroller.value) {
    console.error('No scroller element found');
    return;
  }
  if (!welcomeSection.value) {
    console.error('No welcome section found');
    return;
  }
  if (!smartFactorySection.value) {
    console.error('No smart factory section found');
    return;
  }
  if (!useCasesSection.value) {
    console.error('No use cases section found');
    return;
  }
  if (!efiatoSection.value) {
    console.error('No efiato section found');
    return;
  }
  if (!consultingSection.value) {
    console.error('No consulting section found');
    return;
  }

  createScene(guts.value, isLargeScreen);
  scroller.value.addEventListener('scroll', onScroll);

  if (props.loadSpecialState == SpecialState.Legal) showLegal.value = true;
  if (props.loadSpecialState == SpecialState.Privacy) showPrivacy.value = true;

  if (props.loadState != state.value) {
    store.$patch({ doingNavAnimation: true });
    setTimeout(() => {
      handleNavClick(props.loadState);
      setTimeout(() => {
        const section = document.getElementById(props.loadState);
        if (section) {
          section.scrollIntoView({
            behavior: 'auto',
          });
        }
      }, 500);
    }, 100);
  }
});

onUnmounted(() => {
  cleanupScene();
  scroller.value?.removeEventListener('scroll', onScroll);
});
</script>

<style scoped lang="scss">
footer {
  a {
    color: inherit;
    text-decoration: none;
  }
}
</style>
