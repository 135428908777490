import RevisionedStore from './base';

export interface ShiftXRef {
  name: string;
  color: string;
}

export type ShiftHash = { [euid: string]: ShiftXRef };

export class MetricLayoutDefinition {
  rev: number = 0;
  name: string = '';
  metrics: string[] = [];
  enabled: boolean = false;
}

class MetricNames {
  rev: number = 0;
  overrides: { [key: string]: string } = {};
}

interface ReasonSet {
  euid: string;
  name: string;
  desc: string;
}

export class EntityHierarchy {
  euid: string = '';
  name: string = '';
  key: string = '';
  desc: string = '';
  category: string = '';
  children: EntityHierarchy[] = [];
  parent: EntityHierarchy | null = null;
  depth: number = 0;
  max_depth: number = 0;
  enabled: boolean = true;
  timezone: string = '';
}

export const sessionProfileColumns = [
  { field: 'first_name', header: 'First Name' },
  { field: 'last_name', header: 'Last Name' },
  { field: 'email', header: 'E-Mail' },
  { field: 'role', header: 'Role' },
  { field: 'status', header: 'Status' },
];

export class SessionProfile extends RevisionedStore {
  euid: string = '';
  job_title: string = '';
  phone_number: string = '';
  email: string = '';
  first_name: string = '';
  last_name: string = '';
  role: string = '';
  status: string = '';
  timezone: string = '';
  shifts: ShiftHash = {};
  shift_names: string[] = [];
  subscription: string = '';
  sub_status: string = '';
  entities: EntityHierarchy = new EntityHierarchy();
  scrap_reasons: { [key: string]: ReasonSet } = {};
  rework_reasons: { [key: string]: ReasonSet } = {};
  reject_reasons: { [key: string]: ReasonSet } = {};
  down_reasons: { [key: string]: ReasonSet } = {};
  metric_layout_1: MetricLayoutDefinition = new MetricLayoutDefinition();
  metric_layout_2: MetricLayoutDefinition = new MetricLayoutDefinition();
  metric_layout_3: MetricLayoutDefinition = new MetricLayoutDefinition();
  metric_names: MetricNames = new MetricNames();
  is_super?: boolean;
}

export function stateObj() {
  const newState = new SessionProfile();
  return { ...newState };
}
