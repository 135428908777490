import axios, { AxiosStatic } from 'axios';
import { useRouter, Router } from 'vue-router';

import { useConfirm } from 'primevue/useconfirm';
import { ConfirmationServiceMethods } from 'primevue/confirmationservice';
import { useToast } from 'primevue/usetoast';
import { ToastServiceMethods } from 'primevue/toastservice';

import EventBusInstance from '@service/eventBus';

import { useOverlay, OverlayAPI } from '@components/OverlaySpinner';
import { useDialog } from 'primevue/usedialog';
import { DialogServiceMethods } from 'primevue/dialogservice';

import * as helpers from '@service/helpers';
import * as api from '@service/api';

export abstract class BaseCore {
  axios: AxiosStatic = axios;
  confirm: ConfirmationServiceMethods = useConfirm();
  toast: ToastServiceMethods = useToast();
  overlay: OverlayAPI = useOverlay();
  router: Router = useRouter();
  dialog: DialogServiceMethods = useDialog();
  eventBus: typeof EventBusInstance = EventBusInstance;
  helpers: typeof helpers = helpers;
  api: typeof api.default = api.default;
}

export class Core extends BaseCore {}

export default function useCore(): Core {
  return new Core();
}
