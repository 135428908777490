import axios from 'axios';
import { State } from './state';
import { InventoryState } from '@custom/base/queries/inventory';

export interface BaseActions {
  setKiosk(newKiosk: string): void;
  setDeviceId(newDeviceId: string): void;
  setUserId(newUserId: string): void;
  setSession(newSession: string): void;
  setDomain(newDomain: string): void;
  setTimeout(newTimeout: number): void;
  setLogout(newLogout: boolean): void;
  setTheme(newTheme: string): void;
  setTimezone(newTimezone: string): void;
}

export interface InventoryStateActions {
  updateInventoryState(newInventoryState: Partial<InventoryState>): void;
}

type StoreStateActions = State & BaseActions & InventoryStateActions;

export const inventoryActions: InventoryStateActions = {
  updateInventoryState(this: StoreStateActions, newInventoryState) {
    Object.assign(this.inventoryState, newInventoryState);
  },
};

export const baseActions: BaseActions = {
  setKiosk(this: StoreStateActions, newKiosk: string) {
    this.kiosk = newKiosk;
  },
  setDeviceId(this: StoreStateActions, newDeviceId: string) {
    this.deviceid = newDeviceId;
  },
  setUserId(this: StoreStateActions, newUserId: string) {
    this.userid = newUserId;
  },
  setSession(this: StoreStateActions, newSession: string) {
    this.session = newSession;
    axios.defaults.headers.common = {
      'X-SessionID': newSession,
    };
  },
  setDomain(this: StoreStateActions, newDomain: string) {
    this.domain = newDomain;
  },
  setTimeout(this: StoreStateActions, newTimeout: number) {
    this.timeout = newTimeout;
  },
  setLogout(this: StoreStateActions, newLogout: boolean) {
    this.logout = newLogout;
  },
  setTheme(this: StoreStateActions, newTheme: string) {
    this.theme = newTheme;
  },
  setTimezone(this: StoreStateActions, newTimezone: string) {
    this.timezone = newTimezone;
  },
  ...inventoryActions,
};
