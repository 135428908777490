import { inject, App } from 'vue';
import OverlaySpinner from './overlay.vue';
export default OverlaySpinner;

export const OverlaySymbol: unique symbol = Symbol('OverlaySpinnerSymbol');

// stolen from primevue/utils and made asynv version
function AsyncEventBus() {
  const allHandlers = new Map();

  return {
    on(type, handler) {
      let handlers = allHandlers.get(type);
      if (!handlers) handlers = [handler];
      else handlers.push(handler);

      allHandlers.set(type, handlers);
    },

    off(type, handler) {
      let handlers = allHandlers.get(type);
      if (handlers) {
        handlers.splice(handlers.indexOf(handler) >>> 0, 1);
      }
    },

    async emit(type, evt, arg2) {
      let handlers = allHandlers.get(type);
      if (handlers) {
        return Promise.all(handlers.slice().map((handler) => handler(evt, arg2)));
      }
    },
  };
}

// stealing pattern from primevue...
//   Step #1, define an event bus
export const OverlaySpinnerEventBus = AsyncEventBus();

// Step #2, define our service thingy
export const OverlaySpinnerService = {
  install: (app: App) => {
    const OverlaySpinnerService = {
      show: (minDur: number = 0.8, dark: boolean = false) => {
        OverlaySpinnerEventBus.emit('show', minDur, dark);
      },
      hide: async () => {
        await OverlaySpinnerEventBus.emit('hide', null, null);
      },
    };
    app.config.globalProperties.$overlayspinner = OverlaySpinnerService;
    app.provide(OverlaySymbol, OverlaySpinnerService);
  },
};

// Step #3a, define a generic TS-happy class for our thing
export abstract class OverlayAPI {
  public abstract show(minDur?: number, dark?: boolean);
  public abstract hide(): Promise<void>;
}

// Step #3b, write our vue-esque use* method to inject our non-null-ref
export const useOverlay = (): OverlayAPI => {
  const resolved = inject(OverlaySymbol);
  if (!resolved) throw new Error('Overlay not available!  Check App.vue!');
  return resolved as OverlayAPI;
};

/* not doing this here anymore since using app install above
export const provideOverlay = (overlayRef: any): void => {
  console.log("PROVIDING OVERLAY!")
  provide(OverlaySymbol, overlayRef as OverlayAPI)
  console.log("PROVIDEDOVERLAY!")
  const o = useOverlay();
  console.log(o);
};
*/
