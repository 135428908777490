<template>
  <ValidatedFormField :name="name" :label="label" :validationField="validationField">
    <slot>
      <InputText
        v-model="internalValue"
        :name="name"
        :disabled="disabled"
        v-bind="$attrs"
        @keyup="onKeyUp"
      />
    </slot>
  </ValidatedFormField>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, WritableComputedRef } from 'vue';
import InputText from 'primevue/inputtext';
import { BaseValidation } from '@vuelidate/core';
import ValidatedFormField from '../ValidatedFormField';
export default defineComponent({
  components: {
    ValidatedFormField,
    InputText,
  },

  props: {
    modelValue: {
      type: [String, Number],
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validationField: {
      type: Object as PropType<BaseValidation>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue', 'keyup'],

  setup(props, { attrs, slots, emit }) {
    const internalValue: WritableComputedRef<string | undefined> = computed({
      get() {
        if (props.modelValue) return props.modelValue?.toString();
        return undefined;
      },
      set(value: string | undefined) {
        emit('update:modelValue', value);
      },
    });

    function onKeyUp(e) {
      emit('keyup', e);
    }

    return { internalValue, onKeyUp };
  },
});
</script>

<style scoped></style>
