import axios from 'axios';
import EventBusInstance from '@service/eventBus';

const get = (endpoint, payload?: object) => {
  return new Promise((resolve, reject) => {
    if (payload) {
      axios
        .get(endpoint, { params: payload })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.data['detail'] === 'JWT: NO COMPANY!') {
            EventBusInstance.emit('need-company-init');
          } else if (e.response.data['detail'] === 'JWT: REFRESH SESSION!') {
            EventBusInstance.emit('need-auth-refresh');
          } else {
            reject(e);
          }
        });
    } else {
      axios
        .get(endpoint)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          console.log(e.response);
          if (e.response.data['detail'] === 'JWT: NO COMPANY!') {
            EventBusInstance.emit('need-company-init');
          } else if (e.response.data['detail'] === 'JWT: REFRESH SESSION!') {
            EventBusInstance.emit('need-auth-refresh');
          } else {
            reject(e);
          }
        });
    }
  });
};

const post = (endpoint, payload?: object) => {
  return new Promise((resolve, reject) => {
    if (payload) {
      var form_data = new FormData();
      for (var key in payload) {
        form_data.append(key, payload[key]);
      }
      axios
        .post(endpoint, form_data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      axios
        .post(endpoint)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

const noFormPost = (endpoint, payload?: object) => {
  return new Promise((resolve, reject) => {
    if (payload) {
      axios
        .post(endpoint, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      axios
        .post(endpoint)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

const put = (endpoint, payload: object, raw = false) => {
  return new Promise((resolve, reject) => {
    if (raw) {
      axios
        .put(endpoint, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      var form_data = new FormData();
      for (var key in payload) {
        form_data.append(key, payload[key]);
      }
      axios
        .put(endpoint, form_data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

const patch = (endpoint, payload: object, raw = false) => {
  return new Promise((resolve, reject) => {
    if (raw) {
      axios
        .patch(endpoint, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      var form_data = new FormData();
      for (var key in payload) {
        form_data.append(key, payload[key]);
      }
      axios
        .patch(endpoint, form_data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

const del = (endpoint, payload?: object) => {
  return new Promise((resolve, reject) => {
    if (payload) {
      var form_data = new FormData();
      for (var key in payload) {
        form_data.append(key, payload[key]);
      }
      // special syntax here per: https://masteringjs.io/tutorials/axios/delete
      axios
        .delete(endpoint, { data: form_data })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    } else {
      axios
        .delete(endpoint)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    }
  });
};

const saveItem = (endpoint, id, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/v1/${endpoint}/${id}/`;
    axios
      .post(url, payload)
      .then((response) => {
        resolve('success');
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const createItem = (endpoint, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/v1/${endpoint}/`;
    axios
      .post(url, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const removeItem = (endpoint, id) => {
  return new Promise((resolve, reject) => {
    const url = `/v1/${endpoint}/${id}/`;
    axios
      .delete(url)
      .then((response) => {
        resolve('success');
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const updateItem = (endpoint, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/v1/${endpoint}/${payload.euid}/`;
    axios
      .put(url, payload)
      .then((response) => {
        console.log(response);
        resolve('success');
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const partialUpdateItem = (endpoint, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/v1/${endpoint}/${payload.euid}/`;
    axios
      .patch(url, payload)
      .then((response) => {
        console.log(response);
        resolve('success');
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const updateItemNoEuid = (endpoint, payload) => {
  return new Promise((resolve, reject) => {
    const url = `/v1/${endpoint}/`;
    axios
      .put(url, payload)
      .then((response) => {
        resolve('success');
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default {
  createItem,
  partialUpdateItem,
  updateItem,
  updateItemNoEuid,
  saveItem,
  removeItem,
  get,
  post,
  noFormPost,
  put,
  patch,
  del,
};
