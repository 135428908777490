<template>
  <form class="grid-col-12 grid gap-4" @submit.prevent="onSubmit()">
    <div class="col-span-12 flex gap-6">
      <ValidatedInputText
        v-model="current.first_name"
        name="first-name"
        label="First Name"
        :validationField="v$.first_name"
      />
      <ValidatedInputText
        v-model="current.last_name"
        name="last-name"
        label="Last Name"
        :validationField="v$.last_name"
      />
    </div>
    <div class="col-span-12">
      <ValidatedInputText
        v-model="current.email"
        name="email"
        label="Email Address"
        :validationField="v$.email"
      />
    </div>
    <div class="col-span-12">
      <ValidatedInputText
        v-model="current.subject"
        name="subject"
        label="Subject"
        :validationField="v$.subject"
      />
    </div>
    <div class="col-span-12">
      <ValidatedFormField name="message" label="Message" :validationField="v$.message">
        <TextArea
          :rows="3"
          :autoResize="false"
          class="px-2 py-4 text-lg"
          v-model="current.message"
        ></TextArea>
      </ValidatedFormField>
    </div>
    <div class="col-span-12 mt-6 text-right">
      <Button
        label="Send Message"
        icon="pi pi-envelope"
        class="w-auto px-8 py-4"
        @click="onSubmit()"
      ></Button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { onMounted, reactive } from 'vue';

import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, email } from '@vuelidate/validators';

import ValidatedInputText from '@components/ValidatedInputText';
import ValidatedFormField from '@components/ValidatedFormField';

import useCore from '@service/core';
const c = useCore();

///////////////////////////////////////////
// NOTE: be careful changing this, this same
//  component is use on pocket-e.com also!!
///////////////////////////////////////////

const props = defineProps({
  source: {
    type: String,
    required: true,
  },
  subject: {
    type: String,
    required: true,
  },
  useStatusEmits: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close', 'success', 'fail']);

interface ContactMessage {
  first_name: string;
  last_name: string;
  email: string;
  subject: string;
  message: string;
}

const current = reactive<ContactMessage>({
  first_name: '',
  last_name: '',
  email: '',
  subject: props.subject,
  message: '',
});

const rules = {
  first_name: {
    required,
    maxLength: maxLength(80),
  },
  last_name: {
    required,
    maxLength: maxLength(80),
  },
  email: {
    required,
    email,
    maxLength: maxLength(80),
  },
  subject: {
    maxLength: maxLength(80),
  },
  message: {
    required,
  },
};

const v$ = useVuelidate(rules, current);

async function onSubmit() {
  v$.value.$touch();
  if (v$.value.$invalid) {
    return false;
  }

  const payload = {
    ...current,
    source: props.source,
  };
  const url = '/v1/navutil/marketing/contact/';

  c.overlay.show();
  try {
    await c.api.post(url, payload);
    if (props.useStatusEmits) {
      emit('success');
    } else {
      c.toast.add({
        severity: 'success',
        summary: 'Message Sent',
        detail: 'Thank you for contacting us. We will be in touch shortly.',
        life: 3000,
      });
    }
    emit('close');
  } catch (e) {
    if (props.useStatusEmits) {
      emit('fail');
    } else {
      c.toast.add({
        severity: 'error',
        summary: 'Error',
        detail: 'There was an error sending your message. Please try again.',
        life: 8000,
      });
    }
  }
  c.overlay.hide();
}

onMounted(() => {
  console.log('mounted', props.subject, props.source);
});
</script>

<style lang="scss" scoped></style>
