<template>
  <ValidatedFormField :name="name" :label="label" :validationField="validationField">
    <slot>
      <InputNumber
        v-model="internalValue"
        :name="name"
        :mode="mode"
        :currency="currency"
        :minFractionDigits="minFractionDigits"
        :maxFractionDigits="maxFractionDigits"
        v-bind="$attrs"
      />
    </slot>
  </ValidatedFormField>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, WritableComputedRef } from 'vue';
import InputNumber from 'primevue/inputnumber';
import { BaseValidation } from '@vuelidate/core';
import ValidatedFormField from '../ValidatedFormField';
export default defineComponent({
  components: {
    ValidatedFormField,
    InputNumber,
  },

  props: {
    modelValue: {
      type: Number,
      default: undefined,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validationField: {
      type: Object as PropType<BaseValidation>,
      required: true,
    },
    mode: {
      type: String as PropType<'currency' | 'decimal'>,
      default: undefined,
    },
    currency: {
      type: String,
      default: undefined,
    },
    minFractionDigits: {
      type: Number,
      default: undefined,
    },
    maxFractionDigits: {
      type: Number,
      default: undefined,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { attrs, slots, emit }) {
    const internalValue: WritableComputedRef<number | undefined> = computed({
      get() {
        return props.modelValue;
      },
      set(value: number | undefined) {
        emit('update:modelValue', value);
      },
    });
    return { internalValue };
  },
});
</script>

<style scoped></style>
