<template>
  <table>
    <tr ref="headerRowRef">
      <template v-for="column in state.columns" :key="column.field">
        <Header
          v-if="!column.hidden && !(column.column_type === 'hidden')"
          v-model="state"
          :column="column"
          :noFilter="noFilter"
          :class="{ 'column-scroll': !isRightStatic }"
          @headerClick="$emit('headerClick', $event)"
          @sortClick="$emit('sortClick', $event)"
          @filterClick="$emit('filterClick', $event)"
        />
      </template>
      <th :class="{ 'column-fixed': isRightStatic }" class="control-column">
        <div class="flex justify-center">
          <div
            class="max-w-min cursor-pointer rounded-full bg-none px-1 text-center transition-colors hover:bg-primary hover:text-white"
            @click.stop="$emit('settingsClick')"
          >
            <i class="pi pi-ellipsis-h" />
          </div>
        </div>
      </th>
    </tr>
    <template v-if="state.rows !== undefined && state.rows.length !== 0">
      <Row
        v-for="(row, idx) in state.rows"
        :key="row[dataKey]"
        v-model="state"
        :idx="idx"
        :row="row"
        :dataKey="dataKey"
        :buttonColWidth="buttonColWidth"
        :buttons="buttons"
        :isRightStatic="isRightStatic"
        :isTableScrolling="isTableScrolling"
        :highlightRowId="highlightRowId"
        :colorBools="colorBools"
        @height="checkRowHeight"
      />

      <tr v-if="hasSumColumn" ref="sumRowRef" class="sum-row">
        <template v-for="column in state.columns" :key="'sum-' + column.field">
          <td
            v-if="!column.hidden && !(column.column_type === 'hidden')"
            class="text-right"
            :class="{
              'column-scroll': !isRightStatic,
              'border-cell': column.column_type === 'float',
            }"
          >
            <div v-if="column.sum && state.sums" class="flex flex-col">
              <div class="text-xs font-bold">SUM</div>
              <div>
                {{ c.helpers.getColDefFormatedFloat(column, state.sums[column.field]) }}
              </div>
            </div>

            <template v-else> &nbsp; </template>
          </td>
        </template>

        <td
          class="control-column"
          :class="{
            'column-fixed': isRightStatic,
          }"
        >
          <div class="buttons-container">&nbsp;</div>
        </td>
      </tr>
    </template>

    <template v-else>
      <tr class="empty-row relative">
        <template v-for="column in state.columns" :key="column.field">
          <td
            v-if="!column.hidden && !(column.column_type === 'hidden')"
            class="empty-cell"
          >
            &nbsp;
          </td>
        </template>
        <div
          class="empty-overlay empty-style flex items-center justify-center bg-gray-100 font-bold italic"
        >
          &nbsp;
        </div>
      </tr>
    </template>
  </table>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

import { ButtonDef } from '../buttons';

import Header from './Header.vue';
import Row from './Row.vue';
import { GQLState } from '@service/gql';

import useCore from '@service/core';
const c = useCore();

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

const headerRowRef = defineModel<HTMLTableRowElement | undefined>('headerRowRef', {
  required: true,
});

const sumRowRef = defineModel<HTMLTableRowElement | undefined>('sumRowRef', {
  required: true,
});

const bodyRowHeight = defineModel<number>('bodyRowHeight', {
  required: true,
});

defineProps({
  dataKey: {
    type: String,
    default: 'id',
  },
  buttonColWidth: {
    type: Number,
    required: true,
  },
  buttons: {
    type: Object as PropType<Array<ButtonDef> | null>,
    default: null,
  },
  isRightStatic: {
    type: Boolean,
    default: false,
  },
  isTableScrolling: {
    type: Boolean,
    required: true,
  },
  highlightRowId: {
    type: Number,
    required: false,
    default: 0,
  },
  colorBools: {
    type: Boolean,
    required: true,
  },
  noFilter: {
    type: Boolean,
    default: false,
  },
  lottaRowMode: {
    type: Boolean,
    required: true,
  },
  hasSumColumn: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['headerClick', 'sortClick', 'filterClick', 'settingsClick']);

function checkRowHeight(height: number) {
  if (height > bodyRowHeight.value) bodyRowHeight.value = height;
}
</script>

<style lang="scss"></style>
