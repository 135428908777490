import { defineStore } from 'pinia';
import { actions } from './actions';
import { State, stateObj as baseStateObj } from './base';
import {
  BackendState,
  stateObj as backendStateObj,
  SuperState,
  superStateObj,
} from './backend';
import { NavState, stateObj as navStateObj } from './navstate';
import { MRPReport, stateObj as mrpReportStateObj } from './mrpreport';
import { SessionProfile, stateObj as profileStateObj } from './profile';
import { InoState, inoObj } from './custom/ino';
import { CCCState, cccObj } from './custom/ccc';
import { useStorage } from '@vueuse/core';

import { ref } from 'vue';
//@ts-ignore
const buildDate = ref(new Date(__BUILD_DATE__).getTime());

function useRevisionedStorage(key: string, storage, constructor) {
  // get our value from storage
  const existingVal = useStorage(key, constructor(), storage);
  // only use it if it's from the same build
  if (existingVal.value['version'] === buildDate.value) return existingVal;
  else {
    //@ts-ignore
    if (import.meta.env.MODE != 'development') {
      // otherwise, nuke the existing record
      if (key != baseKey) storage.removeItem(key);
    }
    // and this will force making a new record
    const newVal = useStorage(key, constructor(), storage);
    // and initialize it's value to our timestamp
    newVal.value['version'] = buildDate.value;
    return newVal;
  }
}

////////////////////////////////////////////
const baseKey = 'efiato';
const BaseStore = defineStore({
  id: baseKey,
  state: () => {
    return useRevisionedStorage(
      baseKey,
      window.localStorage,
      baseStateObj,
    ) as unknown as State;
  },
  actions: {
    ...actions,
  },
  persist: true,
});

export type StoreType = ReturnType<typeof BaseStore>;

export function useStore() {
  return BaseStore();
}

////////////////////////////////////////////
const backendKey = 'backend';
const BackendStore = defineStore({
  id: backendKey,
  state: () => {
    return useRevisionedStorage(
      backendKey,
      window.sessionStorage, // NOTE: this is using SESSION storage so we can nav per tab!
      backendStateObj,
    ) as unknown as BackendState;
  },
  actions: {},
});

export type BackendStoreType = ReturnType<typeof BackendStore>;

export function useBackendStore() {
  return BackendStore();
}

////////////////////////////////////////////
const superKey = 'super';
const SuperStore = defineStore({
  id: superKey,
  state: () => {
    return useRevisionedStorage(
      superKey,
      window.sessionStorage, // NOTE: this is using SESSION storage so we can nav per tab!
      superStateObj,
    ) as unknown as SuperState;
  },
  actions: {},
});

export type SuperStoreType = ReturnType<typeof SuperStore>;

export function useSuperStore() {
  return SuperStore();
}

////////////////////////////////////////////
const profileKey = 'profile';
const ProfileStore = defineStore({
  id: profileKey,
  state: () => {
    return useRevisionedStorage(
      profileKey,
      window.localStorage,
      profileStateObj,
    ) as unknown as SessionProfile;
  },
  actions: {},
  persist: true,
});

export type ProfileStoreType = ReturnType<typeof ProfileStore>;

export function useProfileStore() {
  return ProfileStore();
}

////////////////////////////////////////////
const navStateKey = 'navState';
const NavStateStore = defineStore({
  id: navStateKey,
  state: () => {
    return useRevisionedStorage(
      navStateKey,
      window.sessionStorage, // NOTE: this is using SESSION storage so we can nav per tab!
      navStateObj,
    ) as unknown as NavState;
  },
  actions: {},
});

export type NavStateStoreType = ReturnType<typeof NavStateStore>;

export function useNavStateStore() {
  return NavStateStore();
}

////////////////////////////////////////////
const mrpReportKey = 'mrpReport';
const MRPReportStore = defineStore({
  id: mrpReportKey,
  state: () => {
    return useRevisionedStorage(
      mrpReportKey,
      window.sessionStorage, // NOTE: this is using SESSION storage so we can nav per tab!
      mrpReportStateObj,
    ) as unknown as MRPReport;
  },
  actions: {},
});

export type MRPReportType = ReturnType<typeof NavStateStore>;

export function useMRPReportStore() {
  return MRPReportStore();
}

////////////////////////////////////////////
const inoKey = 'ino';
const InoStore = defineStore({
  id: inoKey,
  state: () => {
    return useRevisionedStorage(
      inoKey,
      window.localStorage,
      inoObj,
    ) as unknown as InoState;
  },
  actions: {},
  persist: true,
});

export type InoStoreType = ReturnType<typeof InoStore>;

export function useInoStore() {
  return InoStore();
}

////////////////////////////////////////////
const cccKey = 'ccc';
const CCCStore = defineStore({
  id: cccKey,
  state: () => {
    return useRevisionedStorage(
      cccKey,
      window.localStorage,
      cccObj,
    ) as unknown as CCCState;
  },
  actions: {},
  persist: true,
});

export type CCCStoreType = ReturnType<typeof InoStore>;

export function useCCCStore() {
  return CCCStore();
}
