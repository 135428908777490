import RevisionedStore from '../base';

const today = new Date();
const thirtyDaysAgo = new Date(today);
thirtyDaysAgo.setDate(today.getDate() - 30);
class KPI {
  start: Date = thirtyDaysAgo;
  end: Date = today;
  shift: string = 'All';
}

class Performance {
  year: number | undefined = new Date().getFullYear();
  month: number | undefined = new Date().getMonth() + 1;
  day: number | undefined = undefined;
  shift: string | undefined = undefined;
}

export class CCCState extends RevisionedStore {
  kpi = new KPI();
  performance = new Performance();
}

export function cccObj() {
  const newState = new CCCState();
  return {
    ...newState,
    kpi: new KPI(),
    performance: new Performance(),
  };
}
