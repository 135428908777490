import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBook,
  faBox,
  faBriefcase,
  faCalendarCheck,
  faCartFlatbed,
  faCashRegister,
  faChalkboardTeacher,
  faChartLine,
  faChartPie,
  faCirclePause,
  faCirclePlay,
  faCircleStop,
  faCloudArrowDown,
  faCloudArrowUp,
  faCoffee,
  faComment,
  faDollyFlatbed,
  faExternalLinkAlt,
  faFeatherAlt,
  faGlobe,
  faGlobeAsia,
  faGlobeAmericas,
  faHashtag,
  faIndustry,
  faInfo,
  faKeyboard,
  faLaptopCode,
  faListAlt,
  faMinusCircle,
  faPenToSquare,
  faPlusCircle,
  faPrint,
  faPuzzlePiece,
  faRunning,
  faShieldHalved,
  faTachometerAlt,
  faTools,
  faUserCog,
  faUserFriends,
  faUsersCog,
  faHammer,
  faHardHat,
  faScrewdriver,
  faCogs,
  faRobot,
  faTruck,
  faCalendar,
  faCalendarDay,
  faPaintRoller,
  faThumbsUp,
  faPallet,
  faExchangeAlt,
  faBolt,
  faCaretUp,
  faCaretDown,
  faFilePdf,
  faEnvelopesBulk,
  faLightbulb,
  faPaintbrush,
  faStar,
  faUsers,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

library.add(faBook);
library.add(faBox);
library.add(faBriefcase);
library.add(faCalendar);
library.add(faCalendarCheck);
library.add(faCalendarDay);
library.add(faCartFlatbed);
library.add(faCashRegister);
library.add(faChalkboardTeacher);
library.add(faChartLine);
library.add(faChartPie);
library.add(faCirclePause);
library.add(faCirclePlay);
library.add(faCircleStop);
library.add(faCloudArrowDown);
library.add(faCloudArrowUp);
library.add(faCoffee);
library.add(faComment);
library.add(faDollyFlatbed);
library.add(faExternalLinkAlt);
library.add(faFeatherAlt);
library.add(faGlobe);
library.add(faGlobeAmericas);
library.add(faGlobeAsia);
library.add(faHashtag);
library.add(faIndustry);
library.add(faInfo);
library.add(faKeyboard);
library.add(faLaptopCode);
library.add(faListAlt);
library.add(faMinusCircle);
library.add(faPenToSquare);
library.add(faPlusCircle);
library.add(faPrint);
library.add(faPuzzlePiece);
library.add(faRunning);
library.add(faShieldHalved);
library.add(faTachometerAlt);
library.add(faTools);
library.add(faUserCog);
library.add(faUserFriends);
library.add(faUsersCog);
library.add(faHammer);
library.add(faHardHat);
library.add(faScrewdriver);
library.add(faCogs);
library.add(faRobot);
library.add(faTruck);
library.add(faPaintRoller);
library.add(faPaintbrush);
library.add(faThumbsUp);
library.add(faFilePdf);
library.add(faPallet);
library.add(faExchangeAlt);
library.add(faBolt);
library.add(faCaretUp);
library.add(faCaretDown);
library.add(faEnvelopesBulk);
library.add(faLightbulb);
library.add(faStar);
library.add(faUsers);
library.add(faQuestion);

export default FontAwesomeIcon;
