import RevisionedStore from './base';

export interface Time {
  range: Range;
  day: string;
}
export interface Task {
  part: string;
  op: string;
  prod_line: string;
}

export enum EntityType {
  COMP = 'COMP',
  SITE = 'SITE',
  DEPT = 'DEPT',
  LINE = 'LINE',
  MACH = 'MACH',
  XELP = 'XELP',
}

export interface Entity {
  euid: string;
  depth: number;
  max_depth: number;
  name: string;
  key: string;
}
interface Range {
  start: string;
  end: string;
}

export enum ShiftSummary {
  SHIFT = 'SHIFT',
  SUMMARY = 'SUMMARY',
}

//////////////////////////////////////////////////////
// NOTE ON THESE ENUMS: they must equal core2/master/models/metric.py

// put metric in efficiency metric if needs to be in percentage graph format
export enum EfficiencyMetric {
  EFFICIENCY = 'EFFICIENCY',
  PERFORMANCE = 'PERFORMANCE',
  AVAILABILITY = 'AVAILABILITY',
  QUALITY = 'QUALITY',
  SCRAP_LOSS = 'SCRAP_LOSS',
  SPEED_LOSS = 'SPEED_LOSS',
  PLANNED_DOWN = 'PLANNED_DOWN',
  UNPLANNED_DOWN = 'UNPLANNED_DOWN',
}

// put metric in transaction metric if needs to be summed up
export enum TransactionMetric {
  PRODUCTION = 'PRODUCTION',
  SCRAP = 'SCRAP',
  REJECT = 'REJECT',
  REWORK = 'REWORK',
  GOOD = 'GOOD',
}
//////////////////////////////////////////////////////

type CombinedMetric = EfficiencyMetric | TransactionMetric;

export class NavState extends RevisionedStore {
  title: string = '';
  tabId: string = '';
  layout: number = 1;
  metric: CombinedMetric = EfficiencyMetric.EFFICIENCY;
  entity: Entity = {
    euid: '',
    depth: 0,
    max_depth: 0,
    name: '',
    key: '',
  };
  time: Time = {
    range: {
      start: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        .toISOString()
        .slice(0, 10),
      end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        .toISOString()
        .slice(0, 10),
    },
    day: '',
  };
  shift: string = 'All';
  shift_summary: ShiftSummary = ShiftSummary.SHIFT;
  task: Task = {
    part: '',
    op: '',
    prod_line: '',
  };
}

export function stateObj() {
  const newState = new NavState();
  return { ...newState };
}
