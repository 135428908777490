<template>
  <div
    id="table-footer"
    class="flex flex-col-reverse items-center justify-center gap-4 px-4 pb-3 md:flex-row lg:pb-0"
    :class="{
      'no-API-link': !(showAPILink && !hideFluff),
      'no-Download-link': !(showDownload && !hideFluff),
    }"
    :style="{
      minWidth: '100%',
      maxWidth: maxFooterWidth,
      height: '100%',
    }"
  >
    <Button
      v-if="state.localWhere !== undefined"
      icon="pi pi-filter-slash"
      class="min-w-max"
      @click="clearAllFilters"
      label="Clear Filters"
      rounded
      outlined
    />
    <Button
      v-if="showAPILink && !hideFluff"
      icon="pi pi-paperclip"
      class="min-w-max"
      :label="buttonAPI"
      type="button"
      rounded
      outlined
      @click="onAPILink"
    />
    <Button
      v-if="showDownload && !hideFluff"
      icon="pi pi-download"
      class="min-w-max"
      type="button"
      :label="buttonDownload"
      severity="info"
      rounded
      outlined
      @click="apauloDownload"
    />
    <Button
      v-if="showCreate"
      class="min-w-max"
      icon="pi pi-plus"
      rounded
      outlined
      severity="success"
      :label="buttonCreate"
      @click="onCreate"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, PropType } from 'vue';

import { hideFluff } from '@service/helpers';

import { GQLState } from '@service/gql';

const state = defineModel({
  type: Object as PropType<GQLState<any>>,
  required: true,
});

defineProps({
  buttonAPI: {
    type: String,
    default: 'API Link',
  },
  buttonDownload: {
    type: String,
    default: 'Download',
  },
  buttonCreate: {
    type: String,
    required: true,
  },
  showDownload: {
    type: Boolean,
    default: false,
  },
  showAPILink: {
    type: Boolean,
    default: false,
  },
  showCreate: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['onCreate', 'onDownload', 'onAPILink', 'apauloDownload']);

const maxFooterWidth = ref();

function onCreate() {
  emit('onCreate');
}

// function onDownload() {
//   emit('onDownload');
// }

function apauloDownload() {
  emit('apauloDownload');
}

function onAPILink() {
  emit('onAPILink');
}

async function clearAllFilters() {
  state.value.localWhere = undefined;
  state.value.offset = 0;
  await state.value.refresh();
}

function setMaxTableWidth() {
  maxFooterWidth.value = screen.width - 25 + 'px';
}

window.onresize = () => {
  setTimeout(function () {
    setMaxTableWidth();
  }, 16);
};
</script>

<style lang="scss" scoped></style>
