import { usePassThrough } from 'primevue/passthrough';
import Lara from './lara';

/* we don't seem to be able to add props to this object in inputnumber
   because it's a method and not a straight class... hackin it there boss */

function get_inputnumber_input_root(parent, context) {
  const root = Lara.inputnumber.input.root({ parent, context });
  root.class = root.class || [];
  root.class.push('w-full');
  root.class.push('!h-[53px]');
  if (parent.props.showButtons) {
    root.class.push('rounded-r-none');
  }
  return root;
}

const custom = usePassThrough(
  Lara,
  {
    accordiontab: {
      headerAction: {
        class: [
          'hover:bg-surface-200 dark:hover:bg-surface-900',
          'hover:text-surface-900 dark:hover:text-surface-200',
          'transition-colors',
        ],
      },
    },
    button: {
      root: ({ props, context, parent }) => ({
        class: [
          //  make our border width 2px instead of 1px
          { 'bg-transparent border border-2': props.outlined && !props.plain },
          // Primary Button
          {
            'primary-content':
              !props.link &&
              props.severity === null &&
              !props.text &&
              !props.outlined &&
              !props.plain,
          },
          // Secondary Button
          {
            'text-white dark:text-surface-900':
              props.severity === 'secondary' &&
              !props.text &&
              !props.outlined &&
              !props.plain,
            '!bg-sky-500':
              props.severity === 'secondary' &&
              !props.text &&
              !props.outlined &&
              !props.plain,
            'border border-sky-500 dark:border-sky-400':
              props.severity === 'secondary' &&
              !props.text &&
              !props.outlined &&
              !props.plain,
          },
          // Secondary Text Button
          {
            'text-surface-500 dark:text-surface-300':
              props.text && props.severity === 'secondary' && !props.plain,
          },
          // Secondary Outlined Button
          {
            'text-surface-500 dark:text-surface-300 border border-surface-500 hover:bg-surface-300/20':
              props.outlined && props.severity === 'secondary' && !props.plain,
          },
        ],
      }),
    },
    calendar: {
      input: {
        class: ['!h-[53px]'],
      },
    },
    colorpicker: {
      input: {
        class: ['!h-[53px]', '!w-[53px]'],
      },
    },
    confirmdialog: {
      icon: {
        class: ['text-2xl', 'mr-3', 'mt-2', 'mb-1'],
      },
    },
    datatable: {
      header: {
        class: [
          'text-surface-950',
          'dark:text-surface-900',
          'dark:border-surface-200',
          'border-surface-400',
        ],
      },
      bodyrow: {
        class: ['text-surface-950', 'dark:text-surface-0', 'dark:border-surface-50'],
      },
      column: {
        bodycell: {
          class: [
            'dark:text-surface-0',
            'dark:border-surface-200',
            'border-surface-400',
          ],
        },
        headercell: {
          class: [
            'text-surface-950',
            'dark:text-surface-0',
            'dark:border-surface-200',
            'bg-surface-200',
            'border-surface-400',
            'text-base',
            'dark:bg-gray-500',
            'bg-slate-300',
          ],
        },
      },
    },
    dialog: {
      root: {
        class: ['w-auto', 'dialog-border'],
      },
      header: {
        class: ['!py-1', '!px-2', 'header', 'italic', 'font-extrabold'],
      },
      // closeButtonIcon: { class: ['pb-1'] },
      content: {
        class: ['!px-4', '!py-3'],
      },
      mask: ({ props }) => ({
        style: {
          backdropFilter: 'none !important',
        },
      }),
      transition: () => ({
        enterFromClass: 'opacity-0',
        enterActiveClass: 'duration-100 ease-out',
        leaveActiveClass: 'duration-100 ease-out',
        leaveToClass: 'opacity-0',
      }),
    },
    dropdown: {
      root: {
        class: ['!h-[53px]'],
      },
      input: {
        class: ['flex', 'items-center'],
      },
    },
    /* unused as float label seems half-baked in general
    floatlabel: {
      root: {
        class: [
          // Focus Label Appearance
          '[&>*:last-child]:has-[:focus]:-top-2',
          '[&>*:last-child]:has-[:focus]:left-1',
          '[&>*:last-child]:has-[:focus]:text-xs',
          // Filled Input Label Appearance
          '[&>*:last-child]:has-[.filled]:-top-2',
          '[&>*:last-child]:has-[.filled]:left-1',
          '[&>*:last-child]:has-[.filled]:text-xs',
        ],
      },
    },
    */
    inputnumber: {
      input: {
        root: ({ parent, context }) => ({
          ...get_inputnumber_input_root(parent, context),
        }),
      },
      buttongroup: {
        class: '!h-[53px]',
      },
    },
    inputtext: {
      root: {
        class: ['!h-[53px]', 'text-surface-950'],
      },
      input: {
        class: ['flex', 'items-center'],
      },
    },
    paginator: {
      root: {
        class: ['bg-surface-0 dark:bg-surface-900'],
      },
    },
    textarea: {
      root: {
        class: ['text-surface-800 dark:text-surface-100 font-ubuntu'],
      },
    },
    togglebutton: {
      box: ({ props }) => ({
        class: [
          // Colors
          {
            'primary-content': props.modelValue,
          },
        ],
      }),
    },
  },
  {
    mergeSections: true,
    mergeProps: true,
  },
);

export default custom;
